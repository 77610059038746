import {useEffect} from 'react';
import {getRealtimeMessages} from '../../../../../redux/realtime/realtimeActions';
import {useSelector} from 'react-redux';
import MobilePopup from '../../../../../component/newPopup/mobilePopup/MobilePopup';
import useShow from '../../../../../hooks/useShow';
import {BGImageCenter} from '../../../../../globalVariables/GlobalVariables';
import {TSystemMessage} from '../../../../../redux/realtime/realtime.type';
import SystemMessageSwiper from './systemMessageSwiper';
import setGlobalState from '../../../../../redux/setGlobalState';

function SystemMessageDisplayPop({messagePreview, onClose,isPreview=false}:{messagePreview?: TSystemMessage, onClose?: any,isPreview?: boolean}){
    const  systemMessage = messagePreview? [messagePreview] : useSelector((s: any) => s.realtime.systemMessage?.popMessages);
    const displayPop = !!systemMessage?.find(m=>m.displayInPop);
    const show = useShow();
    const systemMessageXHR = useSelector((s:any) => s.realtime.getSystemMessageXHR);
    const isViewed = useSelector((s:any) => s.realtime.systemMessage.isViewed);
    const eid = useSelector((s:any) => s.event?.eid);

    useEffect(()=>{
        if(!systemMessageXHR && eid)
            getRealtimeMessages();
        if(displayPop &&  (!isViewed || isPreview))
            show.open();
    },[]);
    useEffect(()=>{
        if(displayPop && (!isViewed || isPreview))
            show.open();
        else
            show.close();
    },[systemMessage]);
    
    const close = () => {
        show.close();
        onClose && onClose();
        setGlobalState('realtime.systemMessage.isViewed',true);
    };

    return (
        (displayPop || messagePreview) && show.isOpen? <MobilePopup
            showClass={show.showClass}
            close={close} 
            title={undefined}
            list={undefined}
            forwardRef={undefined}
            dataCyName={'systemMessagePop'}
            cssStyle={`height:380px; width:440px;
            ${ systemMessage?.image && BGImageCenter(systemMessage?.image, 'cover')};`}
        >
            <SystemMessageSwiper isPop={true} defaultMessages={systemMessage} />
        </MobilePopup>:<></>
    );
}

export default SystemMessageDisplayPop;