import React from 'react';
import * as XLSX from 'xlsx';
import * as css from './ExcelExportButton.css';
import Btn from '../../../component/button/btn/Btn';
import {useTranslation} from 'react-i18next';

export const translatedData=(data,lang,t,fieldToTranslateArray = [])=>{
    return (lang !== 'en') ? data.map(obj => {
        let newObject = {};
        for(const property in obj) {
            newObject[t(`${property}`)] = obj[property];
            fieldToTranslateArray?.forEach((item) => {
                if(obj[item]) 
                    newObject[t(`${item}`)] = t(`${obj[item]}`);
            });
        }
        return newObject;
    }) : data;
};

export const exportToExcel = (fileName,data,lang) => {
    const book = XLSX.utils.book_new();
    if(lang === 'he') {
        if(!book.Workbook) book.Workbook = {Views:[{RTL: true}]};
        else if(!book.Workbook.Views) book.Workbook.Views = [{RTL: true}];
        else book.Workbook.Views[0].RTL = true;
    }
    const sheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(book, sheet, fileName);
    XLSX.writeFile(book, `${fileName}.xlsx`);
};

function ExcelExportButton({fileName,data,lang}) {
    const {t}=useTranslation('global');
    
    return (
        <css.backGroundBtn>
            <Btn onClick={()=> exportToExcel(fileName,data,lang)} data-cy = 'exportToExcel'>
                <css.icon /> {t('exportToExcel')}
            </Btn>
        </css.backGroundBtn>
    );
}

export default ExcelExportButton;
