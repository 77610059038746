import styled from 'styled-components';
import {GOLDEN_POPPY, INCH_WORM, LIGHT_GRAY5, PERSIAN_RED, PERSIMMON, tabletMedia, TANGERINE, WHITE} from '../../globalVariables/GlobalVariables';

export const ProgressBar=styled.div`
    width: 270px;
    height: 4px;
    background: ${LIGHT_GRAY5};
    border-radius: 9px;
    transform: rotate(180deg);
    display: flex;
`;

const colors = [PERSIAN_RED,PERSIMMON,TANGERINE,GOLDEN_POPPY,INCH_WORM];
export const ProgressBarChild=styled.div`
    background: ${p=>colors[p.progressIndex]};
    width: ${p => p.progress/p.amount*100}%;
    height: 4px;
    transition: all 0.3s;
`;

export const ProgressBarFrame=styled.div`
    display: flex;
    min-width: 212px;
    ${tabletMedia} {
        display: block;
        min-width: 132px;
    }
    
`;

export const pStatus=styled.div`
    margin: 0 5px;
    font-weight: 600;
`;