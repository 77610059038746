import * as css from './TableTitle.css';
import TableTitleItem from '../tableTitleItem/TableTitleItem';
import {useState,useContext} from 'react';
import {TableContextValue} from '../tableContext/TableContext';
import SmartSearchOnList from '../smartSearchOnList/SmartSearchOnList';
import useEffectOnUpdate from '../../../hooks/useEffectOnUpdate';
import useTablet from '../../../hooks/useTablet';
import MobileTableTitle from './mobileTicketTitle/MobileTableTitle';

const sortByField=filter=>
{
    const {field}=filter;
    const sort = (a, b) => {
        if(typeof a[field] === 'undefined' || typeof b[field] === 'undefined') {
            return 0;
        }
        return (typeof a[field] === 'string' && typeof b[field] === 'string')
            ? a[field].localeCompare(b[field])
            : a[field] - b[field];
    };
    return (a, b) => filter.direction === 'asc' ? sort(a,b):sort(b,a);
};

function TableTitle({titleTableArray,cssColumns}) {
    const [currentFilters, setCurrentFilters] = useState([]);
    const {data,searchColumns,searchArray,setSearchArray}= useContext(TableContextValue);

    useEffectOnUpdate(()=>{
        if(!currentFilters.length) {
            setSearchArray(data);
        } else {
            if(currentFilters.some(f=>f.action === 'sort'))
                sortTable();
        }
    },[currentFilters]);

    const sortTable = () => {
        let sortedResult =[...searchArray];
        [...currentFilters].reverse().forEach(filter => {
            sortedResult=sortedResult.sort(sortByField(filter));
        });
        setSearchArray(sortedResult);
    };

    const filterTable = (filtersItems) =>{
        let filteredData= [...data];
        filtersItems.filter(f=>f.action==='filter').forEach(filter=>{
            filteredData = filter.filterValue !== 'all' ? filteredData.filter(item => {
                return item[filter.filterKey] === filter.filterValue;
            }) : filteredData;
        });
        setSearchArray(filteredData);
    };

    function isNumberField(value) {
        if(typeof value === 'number')
            return true;
        if(typeof value === 'string' && !isNaN(parseFloat(value)) && /^-?\d*\.?\d+$/.test(value))
            return true;
        return false;
    }

    const getHelpers=(item)=>{
        const {field,action}=item;
        const isNumber=isNumberField(data[0]?.[item.field]);
        const currentFilter=currentFilters.find(f=>f.field===item.field);
        const onSort=() =>{
            if(currentFilter)
                setCurrentFilters(prev=>prev.map(i=>i.field===field?{...i, direction: ['des','asc'].find(d=>d!==currentFilter.direction)}:i));
            else
                setCurrentFilters(prev=>[...prev,{field, action, direction: isNumber?'des':'asc'}]);
        };
        const onFilter = (selectItem) =>{
            const filterKey = item.list.filterField;
            if(selectItem.key === 'all')
                onCancel();
            else if(!currentFilter){
                const newFilters = [...currentFilters,{field, filterKey, action,filterValue: selectItem.key || selectItem.text}];
                setCurrentFilters(newFilters);
                filterTable(newFilters);
            }
        };
        const onCancel = (e) => {
            if(currentFilter) {
                const remainingFilters = currentFilters.filter(i => !(i.field === currentFilter.field && i.filterKey === currentFilter.filterKey));
                setCurrentFilters(remainingFilters);
                filterTable(remainingFilters);
            }
            e?.stopPropagation();
        };
        return {onCancel,onFilter,onSort,currentFilter,isNumber};
    };
    const isTablet=useTablet();
    if(data.length<2)
        return <></>;

    titleTableArray.forEach(fieldItem => {
        fieldItem.key=fieldItem.field;
    });

    return (
        <>
            <css.wrapper data-cy="TableTitle" cssColumns={cssColumns}>
                <css.left>
                    {isTablet?
                        <MobileTableTitle {...{titleTableArray,getHelpers,currentFilters}}/>
                        :
                        <>
                            <css.right className='firstColumn'/>
                            {titleTableArray.map((item)=>
                                <TableTitleItem key={item.field} item={item} helpers={getHelpers(item)}/>
                            )}
                        </>}
                    <css.lastColumn className='lastColumn'>
                        <SmartSearchOnList list={data} searchColumns={searchColumns} setSearchArray={setSearchArray}/>
                    </css.lastColumn>
                </css.left>
            </css.wrapper>
        </>
    );
}

export default TableTitle;
