import React, {useState} from 'react';
/* eslint-disable react/no-unescaped-entities */

import {
    Container,
    Button,
    Text,
    Overlay,
    Title,
    Modal,
    CloseIcon,
    MessageText,
    WhatsAppLink,
    DesktopCard,
    Message
} from './NotificationButton.css';
import useMobile from '../../hooks/useMobile';
import {openWhatsApp} from '../../library/whatsApp';

const NotificationButtonComponent = () => {
    const [isOpen, setIsOpen] = useState(false);
    const whatsappNumber = '972508462454';
    // const whatsappUrl = `https://wa.me/${whatsappNumber}`;
    const isMobile = useMobile();

    const MessageContent =
        <Message><Title>לקוחות טיקצ'אק היקרים,</Title>
            <MessageText>
            ברצוננו לעדכן כי הלילה <span style={{marginTop: '20px', fontWeight: 'bold', textAlign: 'center', color: '#000000'}}> יום שלישי 21.1.2025 </span>
            השלמנו בהצלחה העלאת תשתיות חדשות ומשופרות במערכת טיקצ'אק.
            שדרוג זה מהווה חלק מתהליך מתמשך של שיפור השירות והחוויה עבורכם.
            </MessageText>
            <MessageText>
            במהלך השעות הקרובות,
            בזמן שהמערכת מתייצבת,
            ייתכנו שיבושים זמניים בחלק מהפעילויות.
            צוות הפיתוח שלנו עוקב באופן רציף אחר המערכת ומטפל בכל נושא שעולה.
            </MessageText>
            <MessageText>
            אם נתקלתם בהתנהגות חריגה במערכת,
             תדווחו לנו <WhatsAppLink onClick={()=>openWhatsApp(whatsappNumber, 'יש לי בעיות עם ההתחברות אשמח שתחזרו אלי בהקדם!')} target="_blank" rel="noopener noreferrer">[כאן]</WhatsAppLink> כדי שנוכל לטפל בכך
             במהירות המרבית.
            </MessageText>
            <MessageText>
            אנו מודים לכם על הסבלנות וההבנה.
            המטרה שלנו היא להעניק לכם את חווית השימוש הטובה ביותר,
            וצוות התמיכה שלנו זמין עבורכם 24/7.
            </MessageText>
            <MessageText style={{marginTop: '20px', fontWeight: 'bold', textAlign: 'center'}}>
            בברכה,<br />
            צוות טיקצ'אק
            </MessageText>
        </Message>
    ;

    return (
        <>
            <Container>
                <Text>יש הודעה מיוחדת!</Text>
                <Button onClick={() => setIsOpen(true)}>
                    <Text>פתיחת ההודעה</Text>
                </Button>
            </Container>
            {isOpen && (
                <Overlay onClick={() => setIsOpen(false)}>
                    <Modal onClick={e => e.stopPropagation()}>
                        <CloseIcon onClick={() => setIsOpen(false)}>✕</CloseIcon>
                        {MessageContent}
                    </Modal>
                </Overlay>
            )}
            {!isMobile && (
                <DesktopCard>
                    {MessageContent}
                </DesktopCard>
            )}        </>
    );
};

export default NotificationButtonComponent;
