import * as css from './Team.css';
import React, {useRef, useState} from 'react';
import {useEffect} from 'react';
import {getEventTeam} from '../../redux/team/teamActions';
import {useSelector} from 'react-redux';
import MemberBig from './memberBig/MemberBig';
import PageWrapper from '../components/pageWrapper/PageWrapper';
import {useTranslation} from 'react-i18next';
import MemberSmall from './memberSmall/MemberSmall';
import AnotherOne from '../../component/anotherOne/AnotherOne';
import AnotherOneCircle from '../../component/anotherOneCircle/AnotherOneCircle';
import useNavigationLink from '../../hooks/useNavigationLink';
import useMobile from '../../hooks/useMobile';
import Tooltip from '../../component/tooltip/Tooltip';
import FilterList from '../../component/filterList/FilterList';
import useTeamMembers, {getAccountOnlyMembers} from '../../hooks/useTeamMembers';
import {isMaster} from '../../functions/permission';
import {Else, If, Then} from 'react-if';
import MemberSmallLoader from './loadingTeam/memberSmallLoader/MemberSmallLoader';
import MemberBigLoader from './loadingTeam/memberBigLoader/MemberBigLoader';
import AnotherOneCircleLoader from './loadingTeam/anotherOneCircleLoader/AnotherOneCircleLoader';
import AnotherOneLoader from './loadingTeam/anotherOneLoader/AnotherOneLoader';
import CustomScrollbar from '../../component/customScrollbar/CustomScrollbar';
import {mobileMedia} from '../../globalVariables/GlobalVariables';
export const roles=['owner','manager','guide','analyst','conductor','designer','representative'];

export const getRolePermission =  (currentMember,userRole,isPackagePage=false) =>{
    if((currentMember.role==='owner'&&currentMember.status!=='unauthenticated' &&(!isPackagePage || !isMaster())) || currentMember.isPackageRole || (currentMember.role==='manager'&& (userRole!=='master' && userRole!=='owner')))
        return false;
    else if(currentMember.role==='manager')
        return  'owner';
    else
        return  'manage event';
};

export const rolePermission=(item,email)=>((item.role==='owner' || item.role==='manager')&&!email===item.email)? 'owner':'manage event';

function Team() {
    const userEmail = useSelector(s => s.user.email);
    const userRole = useSelector(s => s.event.userEventRole || s.account.userAccountRole);
    const getEventTeamXHR = useSelector(s => s.team.getEventTeamXHR);
    const {t,i18n}= useTranslation('team');
    const tEvent= useTranslation('event');
    const isMobile = useMobile();
    const [isSmallPreview,setIsSmallPreview]=useState(isMobile);
    const [roleFilter,setRoleFilter]=useState(null);
    const teamMemberNavigation = useNavigationLink('teamMemberPop', 'teamMember');
    const containerRef = useRef();

    useEffect(() => {
        if(!getEventTeamXHR)
            getEventTeam().then();
    }, []);
    const {accountMembers,eventMembers}=useTeamMembers();
    const accountOnlyMembers=[...getAccountOnlyMembers(accountMembers,eventMembers).map(m=>({...m,isPackageRole:true}))];

    let sortMembers=[...accountOnlyMembers,...eventMembers].sort((a,b) => roles.indexOf(a.role) - roles.indexOf(b.role));

    if(roleFilter)
        sortMembers=sortMembers.filter(({role})=>role===roleFilter);                  
    const [
        Comp,
        CompAddNew,
        CompLoad,
        CompLoadAddNew
    ] = isSmallPreview
        ? [MemberSmall, AnotherOneCircle, MemberSmallLoader, AnotherOneCircleLoader]
        : [MemberBig, AnotherOne, MemberBigLoader, AnotherOneLoader];
    const list = [...[{text: t('crewMember'),
        key: null,
        onClick: ()=>setRoleFilter(null)}],...(roles||[]).map(role => ({
        text: t(role),
        key: role,
        onClick: ()=>setRoleFilter(role),
    }))];
    const operation=<>
        <Tooltip text={t(`${isSmallPreview?'full':'reduced'}View`)} >
            <css.preview className={!isSmallPreview&&'isSmall'} onClick={()=>setIsSmallPreview(!isSmallPreview)}/>
        </Tooltip>
        <FilterList listProps={{list,...(i18n.dir()==='rtl'?{left:0}:{right:0}), top:0}} filter={roleFilter}/>
    </>;
    const buttons = [{key: 0, onClick: ()=>teamMemberNavigation.open(), text: `${t('crewMember')}`}];
    const mobileActions = [
        {text: t(`${isSmallPreview?'full':'reduced'}View`), onClick: ()=>setIsSmallPreview(!isSmallPreview)},
        {text: t(roleFilter || 'teamMember'), icon: '', list: {text: t('crewMember'), arr: list}},
        ...buttons,
    ];
    const cssStyle = `
        top: 20px;
        inset-inline-end: -20px;
        ${mobileMedia}{
            inset-inline-end: 0px;
        }
    `;
    return (
        <PageWrapper image="" operations={{children:operation,buttons}} mobileActions={mobileActions}>
            <>
                <CustomScrollbar contentRef={containerRef} cssStyle={cssStyle}/>
                <css.grid className={isSmallPreview&&'small'}  data-cy="allTeam" ref={containerRef}>
                    <If condition={getEventTeamXHR === 'LOADING'}>
                        <Then>
                            <css.containerBorder className={`${isSmallPreview&&'small'} ${'first'}`} title={t('owner')}>
                                <CompLoadAddNew/>
                            </css.containerBorder>
                            {[1,2,3,4]?.map((index) => {
                                let className = index===1&&'first';
                                return <css.containerBorder key={index} className={`${isSmallPreview&&'small'} ${className}`} title={index===1 && t('manager')} >
                                    <CompLoad/>
                                </css.containerBorder>;
                            })}
                        </Then>
                        <Else>
                            {sortMembers?.map((item, index) => {
                                let className=`${sortMembers[index-1]?.role!==item.role && 'first'} ${item.role!==sortMembers[index+1]?.role && 'last'}`;
                                let isFirstLine=isMobile && sortMembers[index-2]?.role!==item.role;
                                return <css.containerBorder key={item.uid} data-cy={`${item.role}TeamRole`}   className={`${isSmallPreview&&'small'} ${className}`} title={t(item.role)} isFirstLine={isFirstLine}> 
                                    <Comp  
                                        member={item}
                                        index={index} 
                                        rolePermission={rolePermission(item,userEmail)}
                                        rolePermissionState={getRolePermission(item,userRole)}
                                
                                    />
                                </css.containerBorder>;
                            }
                            )}
                            <css.containerBorder  className={isSmallPreview&&'small'} >
                                <CompAddNew title={tEvent.t('addMembers')} onClick={()=>{
                                    teamMemberNavigation.open();}} dataCyName ="team"/>
                            </css.containerBorder>
                        </Else>
                    </If>
                </css.grid>
            </>
        </PageWrapper>
    );
}

export default Team;
