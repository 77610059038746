import {useTranslation} from 'react-i18next';
import Accordion from '../../../../package/componnent/accordion/Accordion';
import * as css from './marketingPromotionInsights.css';
import InsightsCard from './insightsCard/insightsCard';
import {openEye, symbolEventDark} from '../../../../../assets/symbols/symbols/Symbols.css';
import ButtonIcon from '../../../../../component/button/buttonIcon/buttonIcon';
import List from '../../../../../component/list/List';
import useShow from '../../../../../hooks/useShow';
import {useMemo, useState} from 'react';
import Clicks from '../../../../../assets/icons/clicks';
import InsightsCardData from './insightsCardData/insightsCardData';

function MarketingPromotionInsights({formRef}){
    const {t} = useTranslation('promotionMessage');
    const filterShow = useShow();
    const [filterBy, setFilterBy] = useState({title:''});
    const messagesStats = {} as any;

    const mainStats = [{number:messagesStats.events, text:'eventsText', icon: symbolEventDark.event},
        {number:messagesStats.views, text: 'adViews', icon:openEye},
        {number:messagesStats.clicks, text:'clicksText', icon:Clicks} ,
        {number:messagesStats.bounceRate, text:'bounceRate'} ];

    const secondStats = 
        [{number: messagesStats.inquiries, text: 'inquiries'}, 
            {number: messagesStats.activeCampaigns, text:'activeCampaigns'},
            {number: messagesStats.salesRevenue?(messagesStats.salesRevenue + messagesStats.symbol):null, text: 'salesRevenue'},
            {number: messagesStats.transactions, text: 'transactions'},
            {number: messagesStats.tickets, text: 'tickets'}];

    const filterList = useMemo(()=> [
        {title: t('today'), text: 'today'},
        {title: t('thisMonth'), text: 'thisMonth'},
        {title: t('quarter'), text: 'quarter'},
        {title: t('yearly'), text: 'yearly'},
        {title: t('freeDate'), text: 'freeDate'}
    ],[filterBy]);

    const onListClick = (item) => {
        setFilterBy(item);
    };
    return (<></>);
    return (<css.container> 
        <Accordion title={t('marketingSummary')} dataCyTitle={undefined} forwardRef={formRef}>
            <css.subTitle> {t('generalSummery')}</css.subTitle>
            <css.filterWrapper>
                <ButtonIcon onClick={filterShow.isOpen? filterShow.close: filterShow.open} iconName='filter' text={filterBy?.title || t('filter')} />
                {filterShow.isOpen && <List onClick={onListClick} {...filterShow} list={filterList} />}
            </css.filterWrapper>
            <InsightsCard>
                <InsightsCardData 
                    mainStats={mainStats}
                    secondStats={secondStats}/>
            </InsightsCard>
              
        </Accordion>
    </css.container>);
}
export default MarketingPromotionInsights;