import styled from 'styled-components';
import {BGImageCenter, DEFAULT_COLOR, mobileMedia} from '../../../../../../globalVariables/GlobalVariables';

export const rightSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding-inline: 20px;
  ${mobileMedia} {
    gap: 0px;
    padding-inline: 0px;
  }
`;

export const mainLine = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
export const secondLine = styled(mainLine)`
  border-top: 0.2px ${DEFAULT_COLOR} solid;
  padding-top: 15px;
  ${mobileMedia} {
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px
  }
`;

export const leftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  align-self: start;
`;

export const Buttons = styled.div`
  display: flex;
  gap: 8px;
`;

export const number = styled.div`
  grid-area: number;
  font-size: 20px;
  font-weight: 800;
  line-height: 20px;
  ${mobileMedia} {
    font-size: 16px;
  }
`;
export const text = styled.div`
  grid-area: text;
  font-size: 20px;
  line-height: 20px;
  ${mobileMedia} {
    font-size: 16px;
  }
`;

export const item = styled.div`
display: grid;
grid-auto-columns: 30px auto;
grid-template-areas:
    "icon number"
    "icon text";
`;

export const icon = styled.div<{icon: string}>`
  grid-area: icon;
  width: 20px;
  height: 20px;
  ${p=>BGImageCenter(p.icon, '20px')}
`;

export const secondItem = styled.div`
  display: inline-flex;
  min-width: max-content;
  gap: 2px;
  ${text}, ${number} {
    font-size: 14px;
  }
`;