import {useFormikContext} from 'formik';
import {useSelector} from 'react-redux';
import LoginButton from '../loginButton/LoginButton';

function EnterButton({text, actionName,buttonType, disabled, errorText}) {
    const {submitForm} = useFormikContext();
    const XHR = useSelector((state) => state.user?.[`${actionName}XHR`]);

    const onClick = () => {
        submitForm();
    };
    return (
        <LoginButton disabled={disabled} onClick={onClick} className={XHR} text={text} buttonType={buttonType} errorText={errorText} />
    );
}

export default EnterButton;
