import * as css from './RealtimeHeader.css';
import {useSelector} from 'react-redux';
import {updateRealtimeDesign} from '../../../redux/realtime/realtimeActions';
import Tooltip from '../../../component/tooltip/Tooltip';
import MasterPermission from '../../components/rolePermission/MasterPermission';
import SystemMessageDisplayPop from '../components/windows/systemMessage/systemMessageDisplayPop';
import SystemMessageMenu from '../components/systemMessage/systemMessageMenu/systemMessageMenu';
import MarketingPromotionsMenu from '../components/marketingPromotions/marketingPromotionsMenu/marketingPromotionsMenu';
import {useTranslation} from 'react-i18next';
import CommonActionsList from '../../../component/commonActionsList/CommonActionsList';
import {postEventReport} from '../../../redux/event/eventActions';
import {displayMode, document, manager, message, qr} from '../../../assets/symbols/symbols/Symbols.css';
import getEventUrl from '../../../functions/getEventUrl';
import {LIVE_DOMAIN, TICK_DOMAIN} from '../../../config/config';
import QrCodePopup from '../../../layout/eventMenu/formButtton/QrCodePopup/QrCodePopup';
import useShow from '../../../hooks/useShow';
import SystemMessagePop from '../components/systemMessage/systemMessagePop/systemMessagePop';
import useMobile from '../../../hooks/useMobile';
import useMarketingPromotion from '../components/marketingPromotions/useMarketingPromotion';
import SideFormPopup from '../../../component/sideFormPopup/sideFormPopup';
import useSystemMessage from '../components/systemMessage/useSystemMessage';
import SubMenu from '../../components/subMenu/SubMenu';
import {PageWrapperContext} from '../../components/pageWrapper/PageWrapper';

function RealtimeHeader ({isEvent=false}:{isEvent?:boolean}){

    const {t} = useTranslation('realtime');
    const realtimeDesign = useSelector((s:any) => s.realtime.user.realtimeDesign);
    const isMaster = useSelector((s: any)=>s.user.isMaster);
    const isMobile = useMobile();
    const event = useSelector((s:any) => s.event);
    const {systemMessagesList, realtimeMessageShow} = useSystemMessage();
    const {promotionList, onListClick, page, body, packageName, marketingPromotionsShow} = useMarketingPromotion();
    const QRShow = useShow();
    const QR_REF = '2t24';

    const getUrl =()=>{
        const currentDomain = event.type==='hub'? LIVE_DOMAIN : TICK_DOMAIN;
        const urlPath = getEventUrl(event.domain, event.landingPageUrl||event.name, event.type, currentDomain);
        return `${urlPath}?ref=${QR_REF}`;
    };

    const setDisplay = () => {
        const darkDisplay = realtimeDesign ? !realtimeDesign.darkDisplay : true;
        updateRealtimeDesign('darkDisplay', darkDisplay).then();
    };

    const eventActions =  [
        {text: t('report'), icon: document, onClick: postEventReport},
        {text: t('changedDisplay'), icon: displayMode, onClick: setDisplay},
        {text: t('QRCode'), icon: qr, onClick: QRShow.open}
    ];
    const masterActions = [ 
        {text: t('systemMessage'), icon: message, list: {title: t('systemMessage'), arr: systemMessagesList}},
        {text: t('promotionMessage'), icon: manager, list: {title: t('promotionMessage'), arr: promotionList}}
    ];
    const mobileActions = [
        ...(isEvent ? [...eventActions]: []),        
        ...(isMaster ? [...masterActions]: [])
    ];

    return (
        <css.top>
            {!isMobile && <><MasterPermission>
                <SystemMessageMenu/>
            </MasterPermission>
            <SystemMessageDisplayPop/>
            <MarketingPromotionsMenu/>
            {isEvent&&
                <><Tooltip text={t('changedDisplay')} dataTitleIcon={undefined}>
                    <css.backgroundDisplay onClick={setDisplay} data-cy="changedDisplay" />
                </Tooltip></>
            }
            </>}
            {(isEvent && isMobile) &&<PageWrapperContext.Provider value={{operations: undefined}}>
                <SubMenu image={undefined} className={undefined} openLeft={undefined} routing={undefined} subMenuProps={undefined} mobileActions={mobileActions} >
                    {undefined}
                </SubMenu>
            </PageWrapperContext.Provider>}
            {!isEvent&&<CommonActionsList actions={mobileActions}/>}
            {QRShow.isOpen && <QrCodePopup {...QRShow} url={getUrl()} />}
            {realtimeMessageShow.isOpen && <SystemMessagePop {...realtimeMessageShow} />}
            {marketingPromotionsShow.isOpen && <SideFormPopup menuClicked={onListClick} menuList={promotionList} t={t} bodyComponents={body} {...marketingPromotionsShow}  popupName={packageName} page={page} />}
            {/* temp windows control:
                    {windowsList?.map(win=><ModeBtn click={()=>showHideWin(win, !getShowWindow(win))} key={win} status={getShowWindow(win)} dataTitle={undefined} className={undefined} text={win} dataCyName={undefined} requestStatus={undefined} />)} */}
        </css.top>

    );
}

export default RealtimeHeader;
