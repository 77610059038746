import createReducer from '../createReducer';

let user = JSON.parse(localStorage.getItem('user'));
let exp = JSON.parse(localStorage.getItem('exp'));
let csrfToken = JSON.parse(localStorage.getItem('csrfToken'));

const initialState = {
    isLoggedIn:!!user,
    exp: exp?exp:0,
    message:'',
    token:null,
    socket: {},
    csrfToken: csrfToken || null
};

const reducer = createReducer('auth',initialState);

export default reducer;