import {useTranslation} from 'react-i18next';
import * as css from './TargetStep.css';
import Input from '../../../../component/input/Input';
import {updateEvent} from '../../../../redux/event/eventActions';

export const onClickTargetStep=async (changedValues)=>{
    if(changedValues.target){
        await updateEvent(changedValues);
    }
};

function TargetStep({text}) {
    const {t} = useTranslation('newEvent');

    return (
        <css.wrapper  >
            <Input name={'target'} text={t(text||'targetText')} data-cy="enterTarget" />
        </css.wrapper>
    ); 
}

export default TargetStep;
