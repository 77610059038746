import {useTranslation} from 'react-i18next';
import Animation from '../../../../component/animation/Animation';
import LoginButton from '../../components/loginButton/LoginButton';
import * as css from './Welcome.css';
import login from '../../../../assets/animations/login.json';
import Stage from '../../components/stage/Stage';
import React, {useEffect, useRef, useState} from 'react';
import setGlobalState from '../../../../redux/setGlobalState';
import {useSelector} from 'react-redux';
import Navigation from '../../components/navigation/Navigation';
import NotificationButtonComponent from '../../../../component/notificationButton/NotificationButton';

const Welcome = React.memo(function Welcome() {
    const {t} = useTranslation('login');

    const loginImageIndex = useSelector((s) => s.ui.layout.loginImageIndex);
    const isSignUp = useSelector((s) => s.ui.layout.isSignUp);
    const getRatingsXHR = useSelector((s) => s.ratings.getRatingsXHR);

    const [isOpen, setIsOpen] = useState(
        loginImageIndex || loginImageIndex === 0
    );
    const [showClass, setShowClass] = useState(isOpen ? ' show' : null);

    const timer = useRef(null);
    useEffect(() => {
        if(['SUCCESS', 'FAILED'].includes(getRatingsXHR))
            timer.current = setTimeout(() => {
                setShowClass(null);
            }, 500);
    }, [getRatingsXHR]);

    const close = () => {
        setIsOpen(false);
        setGlobalState('ui.layout.loginImageIndex', null);
    };

    useEffect(() => {
        if(showClass && isOpen) return;
        timer.current = setTimeout(close, 500);
    }, [showClass]);

    useEffect(
        () => () => {
            clearTimeout(timer.current);
        },
        []
    );

    if(!isOpen) return <></>;

    const onClick = () => {
        close();
    };

    return (
        <css.wrap className={showClass} data-cy="WelcomeAnimation">
            <Stage isHeightHeader isDark>
                <css.container>
                    <Animation animation={login} isLoop={false} />
                    <css.top>
                        <css.text>{isSignUp ? t`renew` : <>&nbsp;&nbsp;</>}</css.text>
                        <css.header>
                            <css.rectangle>{t`yourInside1`}</css.rectangle>
                            {t`yourInside2`}
                        </css.header>
                    </css.top>
                    <LoginButton
                        text={t('takeMe')}
                        onClick={onClick}
                        className={'leftArrow'}
                        buttonType="welcome"
                    />
                </css.container>
                {isSignUp && <Navigation currentIndex={2} onClick={() => {}} />}
                <NotificationButtonComponent />
            </Stage>
        </css.wrap>
    );
});

export default Welcome;
