import useShow from '../../../../hooks/useShow';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {pen} from '../../../../assets/symbols/symbols/Symbols.css';
import {getRealtimeMessageById} from '../../../../redux/realtime/realtimeActions';
import {useState} from 'react';

export default function useSystemMessage(){   
    const realtimeMessageShow = useShow();
    const {t} = useTranslation('realtime');
    const [showList, setShowList] = useState(false);

    const messagesList = useSelector((s: any)=>s.realtime.lastsRealtimeMessages)?.map(m=>({
        text: m.smid,
        title: m.officeTitle,
        icon: pen,
        onClick: ()=>{realtimeMessageShow.open(); getRealtimeMessageById(m.smid);}
    }));
    const systemMessagesList = [
        {
            title: t('createNewMessage'),
            text: 'create',
            onClick: realtimeMessageShow.open
        },
        {
            title: t('history'),
            text: 'history',
            list:{title: t('history'), arr: messagesList}
        }
    ].concat(showList?messagesList ||[]:[]);

    return {systemMessagesList, realtimeMessageShow, setShowList};
}