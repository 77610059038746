import {useSelector} from 'react-redux';
import * as css from './PackageManagement.css';
import List from '../../../component/list/List';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import useShow from '../../../hooks/useShow';
import AccountPermission from '../../../pages/components/accountPermission/AccountPermission';

export const manageClick = (type, pid, historyPush) => {
    historyPush({
        pathname: '/p/' + pid + '/' + type
    });
};
export const packageListClick = (item, history, close,otherPid) => {
    history.push({
        ...(otherPid? {pathname: `/p/${otherPid}/home`}:{}),
        search: `?packagePage=${item.text}`
    });
    close&&close();
};

export const packageList = [{text:'package',title: 'packagePermissions'}, {text: 'producer',title:'producer'}, {text: 'bank',title:'bankDetails'},{text: 'bank',title:'budgetItem'},{text: 'bank',title:'clearingAndPayments'}, {text: 'subscription',title:'subscription'}];
function PackageManagement(props) {
    const {t} = useTranslation('layout');
    const account = useSelector((state) => state.account);
    const history = useHistory();

    const packageListOC = useShow( 300);
    const cashOC = useShow();

    return (
        <css.PackageManagement>
            <css.PackageManagementTitle>{account?.title}</css.PackageManagementTitle>
            <css.PackageManagementContain>
                <css.PackageManagementOp className={(!!props.expanded && 'expanded') + ' packageManagementOp'}>
                    <AccountPermission permission={'package crm'}>
                        <css.PMtOpTypeCrm
                            onClick={() => manageClick('crm', account?.pid, history.push)}>{t('crm')}</css.PMtOpTypeCrm>
                    </AccountPermission>
                    <AccountPermission permission={'package package'}>
                        <div>
                            <css.PMtOpTypeDefinition 
                                onClick={packageListOC.isOpen ? packageListOC.close : packageListOC.open}>
                                {t('package')}
                            </css.PMtOpTypeDefinition>
                            {!!packageListOC.isOpen &&
                            <List close={packageListOC.close} title={t('packageSettings')}
                                showClass={packageListOC.showClass}
                                list={packageList} bottom="145" left={props.expanded?'370px':'50px'} t={t}
                                onClick={(item) => packageListClick(item, history, packageListOC.close)}/>}
                        </div>
                    </AccountPermission>
                    <css.PMtOpTypeTeam
                        onClick={() => manageClick('team', account?.pid, history.push)}>{t('team')}</css.PMtOpTypeTeam>
                    {!!props.expanded && <>
                        <css.PMtOpTypeCash  onClick={cashOC.isOpen ? cashOC.close : cashOC.open}>
                            {!!cashOC.isOpen &&
                            < List title={t('cash')} bottom={'45'} left={'-100px'} showClass={cashOC.showClass}
                                list={[{
                                    key: 'cash',
                                    text: t('cash'),
                                    permission: 'package cash'
                                }, {key: 'cash/bank', text: t('bank')}]}
                                onClick={(item) => manageClick(item.key, account?.pid, history.push)}/>}
                            {t('bank')}
                        </css.PMtOpTypeCash>
                        <css.PMtOpTypeCrowd
                            onClick={() => manageClick('crowd', account?.pid, history.push)}>{t('crowds')}</css.PMtOpTypeCrowd>
                    </>}
                </css.PackageManagementOp>
                {!props.expanded && <css.PackageManagementManage onClick={(e) => {
                    e.stopPropagation();
                    props.onClick();
                }}>{t('eventsManager')}</css.PackageManagementManage>}
            </css.PackageManagementContain>

        </css.PackageManagement>
    );
}

export default PackageManagement;