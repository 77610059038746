import React, {useEffect} from 'react';
import {Redirect, Route, Switch, useHistory, useLocation} from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Layout from './layout/Layout';
import {useSelector} from 'react-redux';
import VerifyScreen from './pages/package/componnent/verifyScreen/VerifyScreen';
import Login from './pages/login/signIn/Login';
import SignUp from './pages/login/signUp/SignUp';
import ViewSymbols from './component/ViewSymbols';
import getPathByEventRole from './functions/getPathByEventRole';

export const getDefaultPath=(defaultPid,defaultEid)=>defaultPid?`p/${defaultPid}/home`:`e/${defaultEid}/realtime`;

function Routs() {
    const {defaultPid,defaultEid} = useSelector(state => state.user);
    const event = useSelector(s=>s.event);
    const history = useHistory();
    const location = useLocation();
    let pathLocation=location.pathname.split('/');

    useEffect(() => {
        if(!event.eid)
            return;
        if( (!pathLocation[1]|| (pathLocation[2] && !pathLocation[3]))) {
            const defaultEventPath=getPathByEventRole(event);
            history.push(defaultEventPath);
        }
    }, [event.eid]);

    return (
        <>
            <Switch>
                <Redirect exact from='/p/:pid' to={'/p/:pid/home'}/>
                <Redirect exact from='/e/:eid' to={'/e/:eid/realtime'}/>
                <Route path='/login/forgetPassword' render={() => <Login pageType={'resetPassword'}/>}/>
                <Route exact path='/login' render={() => <Login/>}/>
                <Route exact path='/signUp' render={() => <SignUp/>}/>
                <Route exact path='/viewSymbols' render={() => <ViewSymbols/>}/>

                {/* <ProtectedRoute path='/p/:pid/contact/:approvePid/:approveEmail' component={Layout}/> */}
                <ProtectedRoute  path='/p/:pid/packageVerification' component={VerifyScreen}  />
                <ProtectedRoute path='/e/:eid/:page/:subPage/:pageId' component={Layout}/>
                <ProtectedRoute path='/e/:eid/:page/:subPage' component={Layout}/>
                <ProtectedRoute path='/e/:eid/:page' component={Layout}/>
                <ProtectedRoute path='/e/:eid' component={Layout}/>
                <ProtectedRoute path='/p/:pid/:page/:subPage' component={Layout}/>
                <ProtectedRoute path='/p/:pid/:page' component={Layout}/>
                
                {/*<ProtectedRoute path='/e/:eid' component={null} to={"/realtime"}/>*/}
                {/*<ProtectedRoute path='/p/:pid' component={null} to={`/home`}/>*/}
                <ProtectedRoute path='/' component={null} to={getDefaultPath(defaultPid,defaultEid)}/>
            </Switch>

        </>

    );
}

export default Routs;