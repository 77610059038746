const Vi = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9"
        height="11"
        fill="none"
        viewBox="0 0 9 11"
    >
        <g clipPath="url(#clip0_5722_1762)">
            <path
                stroke="#2EF91C"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m.665 7.15 1.418 2.059a.54.54 0 0 0 .18.167.47.47 0 0 0 .45.008.5.5 0 0 0 .18-.157L7.42 3.042"
            />
        </g>
        <defs>
            <clipPath id="clip0_5722_1762">
                <path fill="#fff" d="M.361.912h8.49V10.5H.36z" />
            </clipPath>
        </defs>
    </svg>
);

export default Vi;
