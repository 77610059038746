import * as css from './ModeBtn.css';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

export const statuses = {proposal: 'proposal', inactive: false, active: true, message: 'message'};

function ModeBtn({click, status, requestStatus, dataTitle ,disabled=false, className,text, dataCyName}) {
    const {t} = useTranslation('permission');
    const [isWait, setIsWait] = useState(false);
    const timer = useRef(null);

    useEffect(() => {
        if(requestStatus === 'LOADING') {
            setIsWait(true);
            timer.current = setTimeout(() => {
                setIsWait(false);
            }, 600);
        }
    }, [requestStatus]);

    useEffect(() => () => clearTimeout(timer.current), []);
    
    function getText() {
        if(text)
            return text;
        if(isWait) 
            return t('works');
        else if(status==true)
            return t('active');
        else if(!status)
            return t('inactive');
    }
    const getClasses = () => (isWait ? ' LOADING ' : requestStatus) + ' ' + ((status === true || status == 1) ? ' active' : (!status ? ' inactive' : status));

    return (<>
        <css.container className={className+' modeBtn ' + (disabled&&' disabled')}>
            <css.text  data-cy ={`${dataCyName ? dataCyName: 'default'}ToggleText`} className={className+' modeBtnText '+getClasses()}>{getText()}</css.text>
            <css.toggle   data-cy ={`${dataCyName ? dataCyName: 'default'}Toggle`}  text={getText()} dataTitle={dataTitle} onClick={!disabled?(e)=>(click&&click(!status,e)):null} className={className+' toggle '+getClasses()} />
        </css.container>
    </>
    );
}

export default ModeBtn;
