import {useTranslation} from 'react-i18next';
import * as css from './Accessibility.css';
import useMobile from '../../../../../hooks/useMobile';
import AccessibilityIcon from '../../../../../assets/icons/Accessibility';
import {ACCESSIBLE_COLOR} from '../../../../../config/config';
import {useSelector} from 'react-redux';
import isLightColor from '../../../../../functions/isLightColor';
import {ThemeProvider} from 'styled-components';
import getColor from '../../../../../functions/getColor';
import setGlobalState from '../../../../../redux/setGlobalState';
import {useField} from 'formik';
import Checkbox from '../../../../../component/checkbox/Checkbox';

function Accessibility() {
    const {t} = useTranslation('ticket');
    const isMobile=useMobile();
    const type = useSelector(s => s.event?.type);
    const tickets = useSelector((s) => s.tickets?.tickets);
    const [{value: color}, , {setValue: setColor}] = useField('color');
    const cssColor = isMobile ? `#${color}` : isLightColor(color)? '#5D5D5D' : '#EEE';
   
    const onClick = () => {
        const newColor = (ACCESSIBLE_COLOR === color)? getColor(null, tickets):ACCESSIBLE_COLOR;
        setColor(newColor);
        setGlobalState('ui.colorsPicker.currentColor',newColor);
    };
    if(type==='course')
        return <></>;

    return (
        <ThemeProvider theme={{color: cssColor}}>
            <css.AccessibilityContain >
                {isMobile&&<AccessibilityIcon color={cssColor}/>}
                <Checkbox selected={ACCESSIBLE_COLOR === color} color={cssColor} onClick={onClick}/>
                {!isMobile&&<AccessibilityIcon color={cssColor}/>}
                {t('accessibilityTicket')}
            </css.AccessibilityContain>
        </ThemeProvider>
    );
}

export default Accessibility;
