import * as css from './TicketsRow.css';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Part from '../../../../../tickets/components/ticketRow/part/Part';
import Detail from '../../../../../tickets/components/ticketRow/Detail/Detail';
import TicketsRow, {getMaxPrice, getMinPrice} from '../ticketsRow/TicketsRow';
import useShow from '../../../../../../hooks/useShow';

const TicketsRowGivechak = ({ticket,active,index,setActiveIndex}) => {
    const {t} = useTranslation('ticket');
    const currencySymbol = useSelector((state:any) => state.event.currencySymbol);
    const pricesShow = useShow();
    const maxPrice = getMaxPrice(ticket);
    const minPrice = getMinPrice(ticket);
    const sold=ticket?.sold??0;
    return (
        <TicketsRow pricesShow={pricesShow} ticket={ticket} active={active} index={index} setActiveIndex={setActiveIndex}  >
            {(ticketOnClick)=><>
                <Part padding={'0'} field={'price'} className={''} cssStyle={undefined} onClick={ticketOnClick}>
                    <css.wrapper>
                        {!ticket.priceFloat ? (<> {(minPrice !== maxPrice) && <Detail text='' value={minPrice} isSymbol sign={currencySymbol} className={'click numberRange'} number={true}/>}
                            <Detail text='' value={maxPrice} isSymbol sign={currencySymbol} className={'click'} number={true}/></>) : (t('freeDonation'))}
                    </css.wrapper>
                </Part>
                <Part field={'sold'} className={''} cssStyle={undefined} padding={undefined} onClick={ticketOnClick}>
                    <Detail text='' value={sold} sign={t('ticketPcs')} className={'click'}/>
                </Part>
                <Part field={'soldPrice'} className={''} cssStyle={undefined} padding={undefined} onClick={ticketOnClick}>
                    <Detail text='' value={ticket?.soldPrice} sign={currencySymbol} isSymbol className={'click'} number={true}/>
                    {ticket?.prices?.length > 1 && <css.arrow isOpen={pricesShow.isOpen}/>}
                </Part>
            </>
            }
        </TicketsRow>
    );
};

export default TicketsRowGivechak;