import styled from 'styled-components';
import {BGImageCenter, BLACK, LIGHT_GRAY, WHITE, dFlexCenter, mobileMedia} from '../../../../globalVariables/GlobalVariables';
import zIndex from '../../../../globalVariables/zIndex';
import {ex, whiteEx, op, opWhite, dragPoints} from '../../../../assets/symbols/symbols/Symbols.css';
import {popUpBody} from '../../../../component/newPopup/mobilePopup/MobilePopup.css';
import {getBackground} from '../../eventRealtime/EventRealtime.css';
import {List} from '../../../../component/list/List.css';
import {EventMenuForm} from '../../../../layout/eventMenu/EventMenu.css';
import {windowBase} from '../../style.css';

export const windowWrapper = styled.div<{ gridArea?: string; height?: string; }>`
    grid-area: ${props=>props.gridArea};
    width: 100%;
    height: ${props=>props.height || '265px'};
    position: relative;
    cursor: auto;
    ${windowBase}
    &  *:not(.free-color *):not(.free-color) {
      color: ${props=>props.theme.isDarkMode && LIGHT_GRAY};
    }
    & .close.pop {
      background-image: url("${props=>props.theme.isDarkMode?whiteEx: ex}");
    }
    ${popUpBody}{
      height:0;
      min-height: 0;
      background-color: ${props=>getBackground(props.theme.isDarkMode)};
      &.show{ 
        min-height: 30%;
        height: fit-content;
      }
    }
`;

export const explainWindowContent = styled.div`
     overflow: hidden;
     height: 80%;
     white-space: break-spaces;
`;
export const explainContainer = styled.div<{isOpen?:boolean, isDarkMode?: boolean}>`
    width: ${props => props.isOpen?'100%':'0'};
    opacity: ${props => props.isOpen?'1':'0'};
    transition: opacity 300ms${props => props.isOpen?'':', width 0ms 300ms'};
    height: 100%;
    background-color: ${props => getBackground(props.theme.isDarkMode)};
    border-radius: 15px;
    position: absolute;
    top: 0;
    bottom: 0;
    inset-inline-end: 0;
    align-items: center;
    z-index: ${zIndex.baseHigh};
    ${mobileMedia}{
      position: unset;
    }
`;

export const windowHeader = styled.div<{ openLayout?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 18px;
  margin: 22px 20px 10px 20px;
  gap: 10px;
  justify-content: space-between;
  width: fit-content;
  position: absolute;
  inset-inline-end: 0;
  z-index: ${zIndex.baseRaised};
  ${List}.show {
    ${p=>p.openLayout&&'height: 260px;'}
  }
  .layoutParent {
    border-bottom: 0;
    height: 40px;
    &.arrow{      
      &::after {
        transition: all 100ms;
      }
      &.open::after {
        transform: rotate(0);
      }
    }

  }
  .itemContent .itemIcon {
    background-size: 18px;

  }
  & .layoutItem {
    border-bottom: 0;
    height: 20px;
  }
`;
export const layoutIcon = styled.div`
  
  .Button.free-color {
    border: none;
  }
    ${mobileMedia}{
        margin-inline-end: 15px;
    }
`;
export const linkButton = styled(EventMenuForm)`
`;
export const howArrivedText = styled.div`
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: all 300ms;
  &:hover {
    text-decoration: underline;
  }
`;
export const questionIcon = styled.button`
  ${dFlexCenter};
  background-color: ${p=>p.theme.isDarkMode?LIGHT_GRAY :BLACK};
  color:  ${p=>p.theme.isDarkMode? '#1E1E1E':WHITE};
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 16px;
  font-weight: bold;
  z-index: ${zIndex.baseHigh};
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;
export const moreActionsIcon = styled.div`
  ${p=>BGImageCenter(p.theme.isDarkMode?opWhite:op, '10px')}
    width: 8px;
    height: 16px;
    cursor: pointer;
    &.disabled{
        opacity: 0.6;
        cursor: unset;
        pointer-events:none;
    }
  
`;
export const dragIcon = styled.div`
  ${p=>BGImageCenter(p.theme.isDarkMode?dragPoints:dragPoints, '24px')}
    width: 11px;
    height: 16px;
    cursor: pointer;
  
`;
export const questionIconMobile = styled(questionIcon)`
    width: 60px;
    height: 60px;
    font-size: 40px;
    margin-top: -90px;
    position: absolute;
    inset-inline-end: calc(50% - 30px);
`;
export const explainWindowTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  margin: 20px 35px 0 50px;
  gap: 5px;
  `;
export const backArrow = styled.button`
  display: inline-block;
  margin-inline-end: 8px;
  font-size: 20px;
  background-color: transparent;
  border: none;
  top: 19px;
  inset-inline-start: 23px;
  cursor: pointer;
   ${p=>p.theme.rtl?'': 'rotate: 180deg;'}
`;