import {area, ticket} from '../../../assets/symbols/symbols/Symbols.css';

export type TType =  'ticket' | 'area'| 'scheduled';
export type TEventTypes={ [key in TType] : {text: string, details:string[], icon:string}}

export const eventTypes = {
    ticket: {
        text: 'ticketEvent',
        details: ['date', 'timeOpen', 'timeStart', 'category', 'location', 'timer'],
        icon: ticket,
        ticketsTitle: {
            soldTitle: 'soldTitleTicket',
            leftTitle: 'leftTitle',
            amountTitle: 'amountTitleTicket',
            averagePricePerTicket: 'averagePricePerTicket'
        }
    },
    area: {
        text: 'areaEvent',
        details: ['date', 'timeOpen', 'timeStart', 'category', 'location', 'timer'],
        icon: area,
        ticketsTitle: {
            soldTitle: 'soldTitleArea',
            leftTitle: 'leftTitle',
            amountTitle: 'amountTitleArea',
            purchaseProcess: 'purchaseProcess',
            free: 'freeSeats',
            close: 'closeSeats',
            saved: 'savedSeats',
            averagePricePerTicket: 'averagePricePerTicket'
        }
    },
    givechak: {
        text: 'givechakEvent',
        details: ['category'],
        icon: ticket,
        translateTitle:'givechak',
        leadingTicket:'leadingTicketGivechak',
        ticketsTitle: {
            soldTitle: 'soldTitleTicket',
            leftTitle: 'leftTitle',
            amountTitle: 'amountTitleTicket',
            averagePricePerTicket: 'averagePricePerTicket'
        },
        donationsArrayTitles: [
            'visitorsFraction', 'visitedToday', 'visitDuration', 'mostFrequentTicketsAmount'
        ],
        windowsList: [
            'cash', 'transactions', 'realtimeData', 'tickets', 'weather', 'message'
        ],
        defaultGridCss: () => { return `
        "cash cash cash cash cash cash transactions transactions transactions transactions transactions transactions"
       "realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData"
       "tickets tickets tickets tickets tickets tickets tickets tickets message message message message"
      `;}
    },
    scheduled: {
        text: 'scheduled',
        details: ['date', 'timeOpen', 'timeStart', 'category', 'location', 'timer'],
        icon: area,
        ticketsTitle: {
            soldTitle: 'soldTitleTicket',
            leftTitle: 'leftTitle',
            amountTitle: 'amountTitleTicket',
            purchaseProcess: 'purchaseProcess',
            averagePricePerTicket: 'averagePricePerTicket'
        }
    },
    default: {
        translateTitle:'ticket',
        leadingTicket:'leadingTicket',
        ticketsTitle:{
            soldTitle: 'soldTitleTicket',
            leftTitle: 'leftTitle',
            amountTitle: 'amountTitleTicket',
            averagePricePerTicket: 'averagePricePerTicket'
        },
        donationsArrayTitles: [
            'visitorsFraction', 'visitedToday', 'visitDuration', 'mostFrequentTicketsAmount', 'averageTickets', 'averagePrice'
        ],
        windowsList: [
            'cash', 'soldTickets', 'transactions', 'realtimeData', 'tickets', 'weather', 'message'
        ],
        defaultGridCss: (showWeather: boolean) => { return `
        "cash cash cash cash soldTickets soldTickets soldTickets soldTickets transactions transactions transactions transactions"
       "realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData"
       "tickets tickets tickets tickets tickets tickets tickets tickets ${showWeather ? 'weather weather weather weather' : 'message message message message'}"
       ${showWeather ? '"message message message message . . . . . . . ."' : ''}
      `;}
    }
};
