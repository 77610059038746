import styled from 'styled-components';
import {BGImageCenter, desktopMedia, scrollbar} from '../../globalVariables/GlobalVariables';

export const Container = styled.div`
    position: relative;
    top: 30px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    direction: rtl;
    flex-direction: column;
    align-items: center;
    z-index: 100;
    gap: 9px;
    width: 100%;
    border-radius: 9px;
    background: #A5EEFC;
    box-shadow: 0px 4px 16.9px 0px rgba(0, 0, 0, 0.25);
    padding: 10px 20px;
    &::before {
        content: '';
        position: absolute;
        width: 70px;
        height: 70px;
        ${BGImageCenter('https://static.tickchak.co.il/images/app/notification.png','contain')};
        right: 0;
        top: -26px;
    }
    ${desktopMedia}{
        display: none;
    }
`;

export const Button = styled.button`
    display: flex;
    height: 36px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    background-color: #FFCC00;
    border: none;
    width: calc(100% - 50px);
    cursor: pointer;
    
    &:hover {
        background-color: #FFE44D;
    }
`;

export const Text = styled.span`
    color: #000;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
`;

export const Modal = styled.div`
    width: 350px;
    height: 100%;
    max-height: calc(100% - 20px);
    max-width: calc(100% - 20px);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #A5EEFC;
    padding: 40px;
    border-radius: 12px;
    z-index: 1000;
    direction: rtl;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
`;

export const Title = styled.h2`
    color: #000;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
`;

export const MessageText = styled.p`
    color: #000;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 16px;
    font-weight: 400;
`;

export const CloseIcon = styled.button`
    position: absolute;
    top: 16px;
    right: 16px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #000;
    padding: 4px;
    line-height: 1;
    
    &:hover {
        opacity: 0.7;
    }
`;

export const Overlay = styled.div`
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    text-align: center;
    height: 100%;
    ${scrollbar}
`;

export const WhatsAppLink = styled.a`
    color: #000;
    text-decoration: underline;
    cursor: pointer;
    font-weight: inherit;
    
    &:hover {
        opacity: 0.8;
    }
    
    &:visited {
        color: #000;
    }
`;
export const DesktopCard = styled.div`
    position: absolute;
    top: 0;
    right: 420px;
    background: #A5EEFC;
    border-radius: 12px;
    padding: 40px;
    box-shadow: 0px 4px 16.9px 0px rgba(0, 0, 0, 0.25);
    height: 640px;
    width: 350px;
    max-height: 100%;
    padding-top: 90px;
    &::before {
        content: '';
        position: absolute;
        width: 120px;
        height: 120px;
        margin-top: -120px;
        right:calc(50% - 60px);
        ${BGImageCenter('https://static.tickchak.co.il/images/app/notification.png','contain')};
    }

    @media (max-width: 768px) {
        display: none;
    }
`;
export const Message = styled.div`
    ${scrollbar};
    height: 100%;
    text-align: center;
`;
