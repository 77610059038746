import React, {useState} from 'react';
import * as css from './Counter.css';
import {formatRealtimeNumber} from '../../functions';
import useEffectOnUpdate from '../../../../hooks/useEffectOnUpdate';

function Counter({value,socketValue, callback}) {
    const [count, setCount] = useState(0);
    useEffectOnUpdate(() => {
        if(!socketValue)
            return;
        const difference = Math.abs(count - socketValue);
        let step = 1;

        if(difference > 50) {
            step = 10;
        } else if(difference > 20) {
            step = 5;
        }

        const interval = setInterval(() => {
            setCount((prevCount) => {
                if(prevCount < socketValue) {
                    return Math.min(prevCount + step, socketValue);
                } else if(prevCount > socketValue) {
                    return Math.max(prevCount - step, socketValue);
                }
                callback();
                clearInterval(interval);
                return 0;
            });

        }, 100);

        return () => clearInterval(interval);
    }, [socketValue]);
    return (
        <css.counter>
            {formatRealtimeNumber(value+count, true)}
        </css.counter>
    );
}

export default Counter;
