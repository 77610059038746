import styled from 'styled-components';
import realtimeFonts from '../../../fonts';
import {EventMenuForm} from '../../../../../layout/eventMenu/EventMenu.css';
import {mobileMedia, tabletPlusMedia} from '../../../../../globalVariables/GlobalVariables';

export const container = styled.div`
  padding: 20px;
  height: 100%;
  ${mobileMedia}{
    padding: 0;
    padding-block: 20px;
    padding-inline: 6px;
  }
`;
export const title = styled.div`
    ${realtimeFonts.windowTitle}
    display: flex;
    justify-content: space-between;
    position: relative;
    width: calc(100% - 40px);
    ${mobileMedia}{
        padding-inline-start: 20px;
    }
`;
export const ticketsPage = styled(EventMenuForm)`
    margin: 0 0;
    margin-inline-end: 15px;
    ${mobileMedia}{
      margin-inline-end: 30px;
    }
`;
export const data = styled.div`
    height: 100%;
    ${tabletPlusMedia}{
      display: flex;
      justify-content: flex-end;
    } 
`;
export const tableTitle = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    width: calc(100% - 90px);
    inset-inline-start: 90px;
    ${mobileMedia}{
      display: none;
    }
`;
export const text = styled.div`

`;
