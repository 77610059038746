import styled from 'styled-components';
import {title} from '../../../../component/input/basicInput/BasicInput.css';
import {mobileMedia, scrollbar} from '../../../../globalVariables/GlobalVariables';
import {NAVIGATION_HEIGHT, NAVIGATION_HEIGHT_MOBILE} from '../navigation/Navigation.css';
import zIndex from '../../../../globalVariables/zIndex';
import {container} from '../../../../component/customScrollbar/CustomScrollbar.css';

export const wrapper=styled.div`
    top:0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: ${zIndex.upperModal};
    background: transparent linear-gradient(360deg, #00FFCA 0%, #05BFDB 33%, #088395 68%, #7E1B89 100%) 0 0 no-repeat padding-box;
    backdrop-filter: blur(3px);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition:all 500ms;
    opacity:0 ;
    &.show{
        opacity:1 ;
    }
    &.ready{
      z-index: unset;
      position: absolute;
      background: white;
    }
    >div:first-child{
       inset-inline-start:35px;
       top:35px;
       ${mobileMedia}{
         inset-inline-start: 20px;
         top: 20px;    
       }
    }
    ${title}{
        font-weight: 800;
    }
    ${container}{
        width: 75%;
    }
`;

export const text=styled.div`
    text-align: center;
    font-size:20px;
    color:#FFFFFF;
    font-weight:bold ;
    letter-spacing: 1.5px;
    width: 650px;
    max-width: 95%;
    padding-bottom:10px;
`;

export const description=styled(text)`
    font-size:18px;
    text-align: center;
    opacity: 0.52;
    font-weight:unset;
    letter-spacing: 0.5px;
`;

export const steps=styled.div`
   height: calc(100% - ${NAVIGATION_HEIGHT}px);
   padding-bottom: 50px;
   ${scrollbar};
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  ${mobileMedia}{
    height: calc(100% - ${NAVIGATION_HEIGHT_MOBILE}px);
  }
  &.ready{
   height: 95%;
  }
  &.valid{
    padding-bottom:10px;
  }
`;
