import * as Yup from 'yup';
import {yupStringMin} from '../../../../functions/validationsYup';

const draftParams=validator=>['draft', {
    is: 1, 
    then: validator,
    otherwise: validator.required()
}];

const draftMinParams=(validator)=>['draft', {
    is: 1, 
    then: validator,
    otherwise: validator.min(1)
}];

const numberValidator=Yup.number();
const dateValidator=Yup.date();
const stringValidator=Yup.string();
const arrayValidator=Yup.array();

const pricesValidator=Yup.array().of(
    Yup.object().shape({
        price: Yup.number().when(...draftParams(Yup.number().min(0).max(99999))),
        title: Yup.string()
    })
);

export const  getValidationSchema = () =>
    Yup.object().shape({
        title: yupStringMin().required(),
        text: yupStringMin(),
        limit: numberValidator.when(...draftParams(numberValidator)),
        prices:pricesValidator.when(...draftMinParams(pricesValidator)),
        payments: Yup.object().shape({}),
        crowd: Yup.object().shape({
            limit: Yup.number().nullable(true),
        }),
        description:Yup.object().shape({
            description:yupStringMin().max(999),
            summary: yupStringMin()
        }),
        extras:Yup.array().of(Yup.object().shape({
            title:  stringValidator.required(),
            price:  numberValidator.required(),
            amount: numberValidator.required(),
            limit:  numberValidator.required()
        })),            
    });

const amountValidator=Yup.number();

export const validationCourse =(hasDates)=>Yup.object().shape({
    amount:amountValidator.when(...draftParams(amountValidator)),
    course: Yup.object().when('draft',{
        is: 1, 
        then: Yup.object().shape({
            startDate: hasDates?dateValidator.required():dateValidator,
            endDate:hasDates?dateValidator.required():dateValidator,
            meetings: hasDates?arrayValidator.min(1):arrayValidator,
        }),
        otherwise:Yup.object().shape({
            startDate:dateValidator.required(),
            endDate:dateValidator.required(),
            meetings: arrayValidator.min(1),
        }) 
    }),
    users:arrayValidator.when(...draftMinParams(arrayValidator)),
    // description:Yup.object().shape({
    //     description:Yup.string().max(700)
    // }),
});