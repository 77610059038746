import React from 'react';
import GenericPieChart from '../genericPieChart/GenericPieChart';
import * as css from './TicketsChart.css';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {formatRealtimeNumber} from '../../functions';

function GivechakChart ({tickets,activeIndex,setActiveIndex}){
    const totalPrice = useSelector((s) => s.realtime?.event?.eventStats?.all?.totalPrice);

    const {t}= useTranslation('ticket');
    const {currencySymbol} = useSelector(s => s.event);
    const  textCenter=() => {
        if(activeIndex!==null)
            return {
                name: tickets[activeIndex]?.title,
                sold: `${tickets[activeIndex]?.sold} ${t('ticketPcs')}`,
            };
        return {
            name: t('allTicketGivechakText'),
            sold: `${tickets[0]?.totalSold} ${t('ticketPcs')}`
        };

    };

    const inlineRect = (
        <>
            <css.ticketName>{textCenter().name}</css.ticketName>
            <css.sold>{textCenter().sold}</css.sold>
        </>
    );

    return (
        <css.container>
            <css.chartContainer>
                {<GenericPieChart
                    data={tickets.map((ticket, index) => ({
                        ...ticket,
                        index,
                        percentage: (ticket.sold / (tickets[0]?.totalSold || 1000)) || 0.01
                    }))}
                    dataKey='percentage'
                    setActiveIndex={setActiveIndex}
                    activeIndex={activeIndex}
                    hoverState={true}
                    inlineRect={inlineRect}
                />}

            </css.chartContainer>
            { totalPrice> 0 && <css.summaryContainer>
                <css.summaryTitle>{`${t('totalSalesGiveChak')} `}<span>{`${currencySymbol}${formatRealtimeNumber(totalPrice)}`}</span>
                </css.summaryTitle>
            </css.summaryContainer>}
        </css.container>
    );
}

export default GivechakChart;
