import * as css from './TicketsRow.css';
import {useSelector} from 'react-redux';
import Part from '../../../../../tickets/components/ticketRow/part/Part';
import Detail from '../../../../../tickets/components/ticketRow/Detail/Detail';
import TicketProgress from '../../../../../tickets/components/ticketRow/ticketProgress/TicketProgress';
import ticketColors from '../../../../../../assets/objects/TicketColors' ;
import PricesDetails from '../pricesDetails/PricesDetails';
import {ThemeProvider} from 'styled-components';
import {useHistory} from 'react-router-dom';

export function getMaxPrice(ticket) {
    return Math.max(ticket?.price ?? 0, ...(ticket?.prices || []).map(p => +p.price || 0));
}

export function getMinPrice(ticket) {
    return ticket?.prices?.length ? Math.min(...ticket.prices.map(p => +p.price || Infinity)) : null;
}

const TicketsRow = ({ticket,active,index,setActiveIndex,children,pricesShow}) => {
    const color = `#${ticket.color}`;
    const ticketsIcon = useSelector((state:any) => state.tickets.ticketsIcon);
    const history = useHistory();

    const ticketOnClick = () =>{
        if(ticket.isMoreTickets){
            history.push(`/e/${ticket.eid}/tickets`);
        }
        else if(ticket.prices?.length > 1){
            pricesShow.open();
        }
    };
    return (
        <ThemeProvider theme={{active}}>
            <css.firstPart className='titleColumn' onClick={ticketOnClick} onMouseEnter={()=>setActiveIndex(index)} onMouseLeave={()=>setActiveIndex(null)}>
                <css.placeDrag className=' place ' />
                <css.ticketIcon icon={ticketColors[`${ticket.color}`]?.[ticketsIcon]} color={color} className='row_icon'>
                    <TicketProgress onClick={(e) => { e.stopPropagation();} } ticket={ticket}>
                        <></>
                    </TicketProgress>
                </css.ticketIcon>
                <css.ticketRight>
                    <css.wrapper>
                        <css.ticketTitle data-cy='titleColumnValue'>{ticket.title}</css.ticketTitle>
                    </css.wrapper>
                </css.ticketRight>
            </css.firstPart>
            
            {children(ticketOnClick)}
            {!!pricesShow.isOpen && <PricesDetails ticket={ticket} show={pricesShow}/>}
        </ThemeProvider>
    );
};

export default TicketsRow;