import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {getMinutesDifference, getTs} from '../../../../library/dates';
import * as css from './LastUpdateTimer.css';
import {useTranslation} from 'react-i18next';

const LastUpdateTimer = () => {
    const lastUpdate = useSelector((s:any) => (s.realtime.event.lastUpdate));
    const [ts, setTs] = useState(getTs());
    const lastMinutes = getMinutesDifference(lastUpdate, ts);
    const {t} = useTranslation('realtime');

    useEffect(() => {
        const interval = setInterval(() => {
            setTs(getTs());
        }, 60000);
        return () => clearInterval(interval);
    }, []);

    return <css.lastUpdate>
        {lastMinutes > 0 ? t('lastUpdate', {minutes: lastMinutes}) : t('updateNow')}
    </css.lastUpdate>;
};

export default LastUpdateTimer;