import styled from 'styled-components';
import {getColor} from '../../../../eventRealtime/EventRealtime.css';
export const header = styled.div`
    display: flex;
    width: 100%;
`;

export const item = styled.div`
    width: 12%;
    text-align: center;
    color: ${p=>getColor(p.theme.isDarkMode)};
    width: calc(65%/3);
`;
export const ticketName = styled(item)`
    width: 35%;
    padding-inline-start: 30px;
`;
