import styled from 'styled-components';

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

export const ModalContent = styled.div`
    background: white;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    width: 90%;
    height: 90%;
`;

export const CloseButton = styled.button`
    position: absolute;
    right: 10px;
    top: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
`;
export const Loader = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;

    @keyframes spin {
        0% { transform: translate(-50%, -50%) rotate(0deg); }
        100% { transform: translate(-50%, -50%) rotate(360deg); }
    }
`;
export const StyledIframe = styled.iframe`
    width: 100%;
    height: calc(100% - 40px);
    border: none;
    transition: opacity 0.3s ease;
`;

export const Container = styled.div`
    padding: 24px 24px 40px;
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(300px, 1fr));
    gap: 16px;
    margin-top: 24px;
    overflow-y: auto;
    padding-right: 8px;
    //flex: 1;
    max-height: calc(100% - 150px);

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: ${props => props.theme.isDarkMode ? '#1a1a1a' : '#f1f1f1'};
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: ${props => props.theme.isDarkMode ? '#4a4a4a' : '#c1c1c1'};
        border-radius: 4px;

        &:hover {
            background: ${props => props.theme.isDarkMode ? '#5a5a5a' : '#a1a1a1'};
        }
    }
`;

export const SearchContainer = styled.div`
    position: relative;
    width: 100%;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 12px 40px 12px 16px;
  border: 1px solid ${props => props.theme.isDarkMode ? '#404040' : '#e2e2e2'};
  border-radius: 8px;
  background: ${props => props.theme.isDarkMode ? '#2c2c2c' : '#fff'};
  color: ${props => props.theme.isDarkMode ? '#fff' : '#000'};
  font-size: 14px;
  text-align: right;
  direction: rtl;

  &:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
  }
`;

export const SearchIcon = styled.span`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  &:before {
    content: '🔍';
    font-size: 16px;
  }
`;

export const Card = styled.div`
    padding: 16px;
    background: ${props => props.theme.isDarkMode ? '#2c2c2c' : '#fff'};
    border: 1px solid ${props => props.theme.isDarkMode ? '#404040' : '#e2e2e2'};
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    width: 100%;
    font-size: 18px;
    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        border-color: ${props => props.theme.isDarkMode ? '#505050' : '#3b82f6'};
    }
    &.title {
        font-weight: 900;
        background-color: #d2d2d2;
    }
`;

export const ExternalLinkIcon = styled.span`
  cursor: pointer;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
  &:after {
    content: '↗️';
  }
`;
