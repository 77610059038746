import NewEventForm from '../../components/newEventForm/NewEventForm';
import * as Yup from 'yup';
import {newEventInitialValues,newEventSteps, newEventValidationSchema} from '../../globalSteps';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import hubLogoStep from '../logoStep/HubLogoStep';
import hubLogoTopStep from '../logoStep/HubLogoTopStep';

function NewHubEvent() {
    const event=useSelector(s=>s.event);
    const {t} = useTranslation(['global', 'newEvent', 'errors', 'layout']);
    const hubSteps={
        title:{...newEventSteps.title,texts:{header:'hubTitleHeader'},compProps:{text:'hubTitleHeader',description:'hubDescriptionText'}},
        location:{...newEventSteps.location,texts:{header:'hubLocationHeader'}},
        logo:{...newEventSteps.logo, RightComp: hubLogoStep, TopComp: hubLogoTopStep},
        description:{...newEventSteps.description,texts:{description:'hubDescriptionDesc',header:'description'}}
    };
    
    const {title,description,locale,location}=newEventInitialValues(event);
    
    const initialValues = {
        title,
        description,
        locale,
        location,
        logo:event.logo
    };

    const {title: titleValid, description: descriptionValid, locale: localValid, location: locationValid}=newEventValidationSchema(t);
    
    const initialValidationSchema = Yup.object().shape({
        title: titleValid,
        description: descriptionValid,
        locale: localValid,
        location: locationValid
    });
    return (
        <NewEventForm
            initialValues={initialValues}
            validationSchema={initialValidationSchema}
            steps={Object.values(hubSteps)}
        />
    );
}

export default NewHubEvent;
