import {useTranslation} from 'react-i18next';
import useShow from '../../hooks/useShow';
import * as css from './CommonActionsList.css';
import ListWrap from '../listWrap/ListWrap';

function CommonActionsList({actions}) {
    const {open,close,isOpen,showClass}=useShow();
    const {t} = useTranslation('global');
    const list = {
        title: 'commonActions',
        arr: actions
    };
    if(!actions || !actions?.length)
        return <></>;
    return (
        <>
            <css.button className={showClass} onClick={open} data-cy="BtnActions"  />
            {!!isOpen && <ListWrap list={list} showClass={showClass} close={close} t={t} onClick={undefined} top={undefined} left={undefined} width={undefined} fixedTop={undefined}/>}
        </>
    );
}
export default CommonActionsList;