import styled from 'styled-components';
import zIndex from '../../globalVariables/zIndex';
import {BGImageCenter, mobileMedia, mobileScreen, scrollbar} from '../../globalVariables/GlobalVariables';
import {menu} from '../../assets/symbols/symbols/Symbols.css';
export const BACKGROUND_COLOR='#FAFAFA';

export const Popup = styled.div`
  position: fixed;
  max-width: 95%;
  height: 100%;
  top: 0;
  inset-inline-end: -790px;
  width: 790px;
  background:${BACKGROUND_COLOR} ;
  transition: all 500ms;
  z-index: ${zIndex.basicModal};
  border-radius: ${props => (props.theme.rtl ? '0px 10px 10px 0px' : '10px 0px 0px 10px')};

  &.show {
    inset-inline-end: 0px;
  }

  @media screen and (max-width: ${mobileScreen}) {
    width: 100%;
    max-width: 100%;
    border-radius: 0px;
  }
`;

export const Management = styled.div`
  margin-top: 15px;
  inset-inline-end: 10px;
  position: absolute;
`;

export const popUpbody = styled.div`
    padding: 0 25px;
    height: calc(100% - 130px);
    ${scrollbar};   
    margin-top:20px;
    margin-bottom: 20px;
    &.showBanner{
        height: calc(100% - 91px);
    }
 	 ${mobileMedia} {
	    padding: 0 15px;
      &.showBanner{
        height: calc(100% - 250px);
      }
	
  	}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 51px;
  border-bottom: 1px solid #DDD;

`;
export const Close = styled.div`
  width: 50px;
  padding-inline-start: 15px;

`;

export const Right = styled.div`
  width: 50%;
  display: flex;

`;
export const TitleAccount = styled.div`
  width: calc(100% - 50px);
  display: flex;
  letter-spacing: 0.5px;
  white-space: nowrap;
  align-items: center;

  ${mobileMedia} {
    display: block;

  }
`;
export const Title = styled.div`
  letter-spacing: 1px;
`;

export const popupName = styled.div`
  font-weight: 600;
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;

  ${mobileMedia} {
    .search {
      &.focus {
        position: fixed;
        width: 90vw;
        background-color: #FAFAFA;
        left: 3vw;
        z-index: ${zIndex.baseHigh};
      }

    }
  }
`;
export const search = styled.div`
  width: calc(100% - 120px);
  height:50px;
  .search.focus{
    position: absolute;
    left: 0;
    z-index: ${zIndex.baseHighest};
    background: #fff;
  }
  ${mobileMedia} {
    .searchText {
      padding: 0;
    }
  }
`;
export const menuDiv = styled.div`
${BGImageCenter(menu, '18px')}
  width: 60px;
  height: 20px;

`;

