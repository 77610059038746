import {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {unVerified} from '../../assets/symbols/symbols/Symbols.css';
import useNavigationLink from '../../hooks/useNavigationLink';
import useShowModal from '../../hooks/useShowModal';
import {bounceMessage} from '../../layout/userMessages/UserMessage';
import AttachedMessage from '../../pages/components/attachedMessage/AttachedMessage';
import Shaking from '../../pages/components/shaking/Shaking';
import {updateEventSettings} from '../../redux/event/eventActions';
import * as css from './EventActivation.css';
import {openDesignPop} from '../../layout/Layout';
import {getEventTickets} from '../../redux/tickets/ticketsActions';
import {useEffect} from 'react';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {isMaster} from '../../functions/permission';

function EventActivation({Comp,compProps}) {
    const {t} = useTranslation('layout');
    const event = useSelector(state => state.event);
    const active=event.settings?.event?.active;
    const account = useSelector(s => s.account);
    const tickets = useSelector(s => s.tickets.tickets);
    const verificationPop = useNavigationLink('verificationPop', 'open');
    const hasActiveTickets = tickets?.some(t => t.active)||event.type==='frontpage'||event.hasScheduledOrPriceFloatTicket    ;

    const history = useHistory();

    const ref = useRef(null);
    const {open, close, isOpen, showClass} = useShowModal(ref);
    const [popTypeToOpen, setPopTypeToOpen] = useState('unverified');
    const getEventTicketsXHR=useSelector(s=>s.tickets.getEventTicketsXHR);

    useEffect(()=>{
        if(!getEventTicketsXHR) {
            getEventTickets().then();
        }
    },[]);

    const popToShow={
        unverified:{ 
            texts:{title:t('weLocked'),content:t(account.userAccountRole==='owner'?'inOrderToProtectYou':'ownerNeedsToVerify')},
            className:'white',
            btnArr:[{text:t(account.userAccountRole==='owner'?'verifyNow':'gotIt'),onClick:(e)=>account.userAccountRole==='owner'?openPop(e):close()}]},
        noActiveTickets:{ 
            texts:{title:t('weLocked'),content:t('noActiveTicketsMessage')},  
            background:'76D6E8',
            btnArr:[{text:t('takeMeToTickets'),onClick:()=>{history.push(`/e/${event.eid}/tickets`);close();}},{text:t('later'),onClick:()=>close()}]}};

    let {texts,background,className,btnArr}=popToShow[popTypeToOpen];

    const updateEvent =  () => {
        updateEventSettings('active',!active,'event');
    };
   
    const openPop = (e)=>{
        e.stopPropagation();
        verificationPop.open();
    };

    const updateStatus = async () => {
        if(active) 
            bounceMessage(t('userMessages', {event: event?.title}), 'attention', {text:t('btnMessage1'), onClick:updateEvent}, {text:t('anotherTime')} );
        else {
            if(((account.ownerEmailVerified===1 && account.termsVerified)||isMaster()) && hasActiveTickets){
                openDesignPop(); 
                if(event.type==='course'&&tickets.some(ticket=>ticket.draft))
                    bounceMessage(t('draftTicketsMessage'));
                await updateEvent();
            }   
            else {
                if(event.type==='timetable' || event.hasScheduledTicket){
                    updateEvent();
                } else {
                    setPopTypeToOpen(hasActiveTickets?'unverified':'noActiveTickets');
                    open();
                }
            }
        }    
    };

    return (
        <css.wrapper ref={ref} data-cy="eventActivationBtn">
            <Shaking className={(active===0 && (!(account.ownerEmailVerified===1 && account.termsVerified)||!hasActiveTickets))&&'shake'}>
                <Comp {...compProps} onClick={updateStatus} click={updateStatus}/>
            </Shaking>   
            {isOpen&& <AttachedMessage 
                texts={texts} 
                icon={unVerified} 
                close={close}
                showClass={showClass}
                background={background}
                className={className}
                btnArr={btnArr}/>
            }
        </css.wrapper>
    );
}

export default EventActivation;
