import SettingItem from '../../settingItem/SettingItem';
import AddGooglePingItem from '../../addGooglePingItem/AddGooglePingItem';

function HubMailingForm() {
    
    return (
        <>
            <SettingItem name={'tickchakIndex'} />
            <SettingItem name={'glatticketIndex'} />
            <SettingItem name={'isIndexed'}>
                <AddGooglePingItem />
            </SettingItem>
            <SettingItem name={'hideFollow'}/>
        </>
    );
}

export default HubMailingForm;
