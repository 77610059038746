import styled from 'styled-components';
import {insightsItem, section, title} from '../../windowItems/windowItems.css';

export const container = styled.div`
  height: 100%;
  padding: 20px;
  padding-bottom: 0;
`;
export const content = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 30px);
  gap: 15px;
  ${section}{
    flex: 1;
    max-width: fit-content;
    min-width: 50%;
    padding-inline: 0;
  }
  ${title}{
    min-width: max-content;
  }
`;

export const insights = styled.div`
  padding-bottom: 5px;
  height: 50%;
  justify-content: space-between;
  border-radius: 8px;
  display: flex;
  align-items: flex-end;
`;
export const insightsNumbers = styled.div<{ eventType?: string; }>`
  height: 98%;
  display: flex;
  flex-direction: column;
  justify-content: ${p=> p.eventType === 'area'? 'space-between' : 'flex-end'};
  ${insightsItem} {
    margin-bottom: ${p=> p.eventType === 'area'?0:5}px;   
  }
`;
