import styled from 'styled-components';
import {BGImageCenter, getStaticImage, mobileMedia, scrollbar} from '../../../globalVariables/GlobalVariables';

export const popContain=styled.div`
    width: 100%;
    display: flex;
    gap:30px;
    justify-content: center;
    align-items: center;
    position: relative;
    &::before{
        content: ''; 
        ${BGImageCenter(getStaticImage('app/cashMan.png'),'contain')};
        position: absolute;
        width: 155px;
        height: 155px;
        inset-inline-end: 40px;
        bottom: -25px;
        ${mobileMedia}{
            content: unset;
        }
    }   

    ${mobileMedia}{
        display: unset;
        height: calc(100% - 60px);
        ${scrollbar}
    }
`;

