import NewEventForm from '../../components/newEventForm/NewEventForm';
import * as Yup from 'yup';
import {newEventInitialValues,newEventSteps, newEventValidationSchema} from '../../globalSteps';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

function NewLecturesEvent() {
    const event=useSelector(s=>s.event);
    const {t} = useTranslation(['global', 'newEvent', 'errors', 'layout']);
   
    const lecturesSteps = [
        newEventSteps.title,
        newEventSteps.location, 
        newEventSteps.date,
        newEventSteps.artists,
        newEventSteps.description,
    ];
    const {title,locale, location, date, artists, description}=newEventInitialValues(event);
    
    const initialValues = {
        title,
        locale,
        location,
        date,
        artists,
        description
    };
    const {title: titleValid, description: descriptionValid, date: dateValid, locale: localValid, location: locationValid}=newEventValidationSchema(t);
    
    const initialValidationSchema = Yup.object().shape({
        title: titleValid,
        description: descriptionValid,
        locale: localValid,
        location: locationValid,
        date: dateValid
    });
    return (
        <NewEventForm
            initialValues={initialValues}
            validationSchema={initialValidationSchema}
            steps={lecturesSteps}
        />
    );
}

export default NewLecturesEvent;
