import * as css from './CreatePassword.css';
import Vi from '../../../../assets/icons/vi';
import Ex from '../../../../assets/icons/ex';
import {useTranslation} from 'react-i18next';

function SettingsTooltip({display, tooltipObj}){
    const {t} = useTranslation('login');

    return (<css.tooltip height={(Object.keys(tooltipObj).length-1)*18} display={display}>
        <css.length>
            <css.lengthRule isValid={tooltipObj.minLength.isValid}>{t(tooltipObj.minLength.text)}</css.lengthRule>
            ● 
            <css.lengthRule isValid={tooltipObj.maxLength.isValid}>  {t(tooltipObj.maxLength.text)}</css.lengthRule>
        </css.length>
        {display && Object.keys(tooltipObj).map((tooltip, index) => (!tooltip.includes('Length') && <css.rule key={index}>
            <css.icon>{tooltipObj[tooltip].isValid?<Vi />:<Ex />}</css.icon> 
            {t(tooltipObj[tooltip].text)}
        </css.rule>))}
    </css.tooltip>);
}
export default SettingsTooltip;