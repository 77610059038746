import styled from 'styled-components';
import {DEFAULT_COLOR, mobileMedia} from '../../../../../../globalVariables/GlobalVariables';

export const card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  margin-block: 28px 16px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);  
  border-radius: 5px;
  background-color: ${DEFAULT_COLOR}0D;
  border: 3px ${DEFAULT_COLOR} solid;
  ${mobileMedia} {
    padding: 15px;
  }
`;
