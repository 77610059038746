export default function MarketingMessage () {
    return (    
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Group_9786"
            width="40.121"
            height="24.1"
            data-name="Group 9786"
            viewBox="0 0 29.121 17.1"
        >
            <defs>
                <clipPath id="clip-path">
                    <path
                        id="Rectangle_3332"
                        fill="none"
                        d="M0 0h29.121v17.1H0z"
                        data-name="Rectangle 3332"
                    />
                </clipPath>
            </defs>
            <g id="Group_9785" clipPath="url(#clip-path)" data-name="Group 9785">
                <path
                    id="Path_13591"
                    fill="#161616"
                    d="M11.242 2.19a1.95 1.95 0 0 0-1 .12 2.4 2.4 0 0 0-1.026.8 14 14 0 0 0-1.14 1.938C7.069 6.949 5.872 9.2 4.242 9.389a2.87 2.87 0 0 1-2.388-.821 2.6 2.6 0 0 1-.439-.6 3.3 3.3 0 0 0-.034.581c.051 1.129.792 2.3 3.454 2.206a9.33 9.33 0 0 0 6.424-3c1.169-1.226 1.881-1.847 2.725-1.847s1.545.621 2.725 1.847a9.3 9.3 0 0 0 6.418 3c2.668.1 3.409-1.077 3.449-2.206a3.3 3.3 0 0 0-.028-.581 2.8 2.8 0 0 1-.445.6 2.87 2.87 0 0 1-2.383.821c-1.625-.188-2.827-2.44-3.83-4.343a14 14 0 0 0-1.14-1.938 2.5 2.5 0 0 0-1.032-.8 1.96 1.96 0 0 0-1-.12l-1.55.188a9 9 0 0 1-1.186.114 9 9 0 0 1-1.182-.112c-.325-.04-.77-.091-1.556-.188m-1.45-1.038a3.3 3.3 0 0 1 1.585-.205c.6.074 1.163.148 1.568.205a10 10 0 0 0 1.037.091 10 10 0 0 0 1.026-.091c.422-.057.969-.131 1.579-.205a3.3 3.3 0 0 1 1.585.205 3.77 3.77 0 0 1 1.55 1.169 14 14 0 0 1 1.26 2.149c.872 1.625 1.892 3.563 2.873 3.677a1.61 1.61 0 0 0 1.357-.439 1.5 1.5 0 0 0 .416-.855.5.5 0 0 0 .017-.148v-.018c-.046-.741.222-1.077.667-1.123h.011c.57-.051 1.009.678 1.169 1.123a4.6 4.6 0 0 1 .331 1.892c-.063 1.733-1.089 3.557-4.737 3.437A10.75 10.75 0 0 1 15.8 8.625c-.929-.98-1.436-1.476-1.818-1.476s-.895.5-1.824 1.476a10.75 10.75 0 0 1-7.279 3.392C1.227 12.136.2 10.312.127 8.58a4.9 4.9 0 0 1 .336-1.893 2.04 2.04 0 0 1 .73-1 .8.8 0 0 1 .371-.143l.046.017c.462.028.758.371.724 1.123v.165a1.5 1.5 0 0 0 .422.855 1.62 1.62 0 0 0 1.357.439c.969-.114 2-2.052 2.856-3.677a13.6 13.6 0 0 1 1.28-2.145 3.7 3.7 0 0 1 1.545-1.169"
                    data-name="Path 13591"
                    transform="translate(.586 4.365)"
                />
                <path
                    id="Path_13592"
                    fill="none"
                    stroke="#5a5b5e"
                    strokeMiterlimit="10"
                    strokeWidth="0.25"
                    d="M11.242 2.19a1.95 1.95 0 0 0-1 .12 2.4 2.4 0 0 0-1.026.8 14 14 0 0 0-1.14 1.938C7.069 6.949 5.872 9.2 4.242 9.389a2.87 2.87 0 0 1-2.388-.821 2.6 2.6 0 0 1-.439-.6 3.3 3.3 0 0 0-.034.581c.051 1.129.792 2.3 3.454 2.206a9.33 9.33 0 0 0 6.424-3c1.169-1.226 1.881-1.847 2.725-1.847s1.545.621 2.725 1.847a9.3 9.3 0 0 0 6.418 3c2.668.1 3.409-1.077 3.449-2.206a3.3 3.3 0 0 0-.028-.581 2.8 2.8 0 0 1-.445.6 2.87 2.87 0 0 1-2.383.821c-1.625-.188-2.827-2.44-3.83-4.343a14 14 0 0 0-1.14-1.938 2.5 2.5 0 0 0-1.032-.8 1.96 1.96 0 0 0-1-.12l-1.55.188a9 9 0 0 1-1.186.114 9 9 0 0 1-1.182-.112c-.327-.04-.772-.091-1.558-.188ZM9.794 1.152a3.3 3.3 0 0 1 1.585-.205c.6.074 1.163.148 1.568.205a10 10 0 0 0 1.037.091 10 10 0 0 0 1.026-.091c.422-.057.969-.131 1.579-.205a3.3 3.3 0 0 1 1.585.205 3.77 3.77 0 0 1 1.55 1.169 14 14 0 0 1 1.26 2.149c.872 1.625 1.892 3.563 2.873 3.677a1.61 1.61 0 0 0 1.357-.439 1.5 1.5 0 0 0 .416-.855.5.5 0 0 0 .017-.148v-.018c-.046-.741.222-1.077.667-1.123h.011c.57-.051 1.009.678 1.169 1.123a4.6 4.6 0 0 1 .331 1.892c-.063 1.733-1.089 3.557-4.737 3.437A10.75 10.75 0 0 1 15.8 8.625c-.929-.98-1.436-1.476-1.818-1.476s-.895.5-1.824 1.476a10.75 10.75 0 0 1-7.279 3.392C1.227 12.136.2 10.312.127 8.58a4.9 4.9 0 0 1 .336-1.893 2.04 2.04 0 0 1 .73-1 .8.8 0 0 1 .371-.143l.046.017c.462.028.758.371.724 1.123v.165a1.5 1.5 0 0 0 .422.855 1.62 1.62 0 0 0 1.357.439c.969-.114 2-2.052 2.856-3.677a13.6 13.6 0 0 1 1.28-2.145 3.7 3.7 0 0 1 1.545-1.169Z"
                    data-name="Path 13592"
                    transform="translate(.586 4.365)"
                />
            </g>
        </svg>      
        
    );        
}    
