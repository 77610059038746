import React, {useContext, useState} from 'react';
import * as css from './SubMenu.css';
import {useTranslation} from 'react-i18next';
import Boxoffice from '../../../layout/boxoffice/Boxoffice';
import Close from '../../../component/close/Close';
import LeftPageDefault from '../pageWrapper/leftPageDefault/LeftPageDefault';
import SubMenuMobile from '../subMenuMobile/SubMenuMobile';
import useTablet from '../../../hooks/useTablet';
import Routing from '../routing/Routing';
import {Link, useLocation} from 'react-router-dom';
import Operations from '../../../component/operations/Operations';
import {PageWrapperContext} from '../pageWrapper/PageWrapper'; 
import hasSpecificTranslate from '../../../library/hasSpecificTranslate.js';
import CommonActionsList from '../../../component/commonActionsList/CommonActionsList';

export const getPageHeader=(page,subPage,t)=>{
    if(subPage)
        return hasSpecificTranslate(`${subPage}PageTitle`,t)||t(subPage);
    return hasSpecificTranslate(`${page}PageTitle`,t)
        ?t(`${page}PageTitle`)
        :(hasSpecificTranslate(`${page}Main`,t)||t(page));
};

function SubMenu({image, className, children,openLeft,routing,subMenuProps, mobileActions}) {
    const {t} = useTranslation('layout');
    const isTablet = useTablet();

    const {operations} = useContext(PageWrapperContext);

    const [showOp,setShowOp]=useState(0);
    const {pathname} = useLocation();

    if(!routing){
        routing={};
        const [,,,page,subPage,pageId]=pathname.split('/');
        routing.title=getPageHeader(page,subPage,t);
        if(pageId)
            routing.subTitle==t(`${pageId}PageTitle`)===`${pageId}PageTitle`?t(pageId):t(`${pageId}PageTitle`);
    }
    const back = pathname.substring(0,pathname.lastIndexOf('/'));

    return (
        <css.subMenuBackground className={showOp&&'show'} onClick={()=>setShowOp(0)}>
            <css.subMenu className={showOp&&'show'} height={(operations?.buttons?operations.buttons.length:0)*35 + 150} onClick={(e)=>e.stopPropagation()} >
                {isTablet&&<><css.subMContain>
                    {!!mobileActions && <CommonActionsList actions={mobileActions} />}
                    {!!children && <SubMenuMobile Comp={children||LeftPageDefault} subMenuProps={subMenuProps}/>}
                    <Boxoffice className={'react '+(showOp &&' show')}/>
                </css.subMContain>
                </>}
                
                <css.warpAll className={`${(showOp &&'show ')} ${openLeft&&'openLeft '} ${!routing.id && ' changeCss'}`}>
                    {!isTablet&&<css.right>
                        {routing.id?<>
                            <Link to={back}>
                                <css.back/>
                            </Link>
                            <Routing {...routing}/>
                        </>:<css.pageHeader>{routing.title}</css.pageHeader>}
                    </css.right>}
                    {operations && <Operations />}
                    {(isTablet&&showOp)&&<css.close onClick={()=>setShowOp(0)}><Close size={'15px'} />{t('close')}</css.close>}
                </css.warpAll>
                <css.smImage image={image} className={`${className} ${openLeft&&'openLeft'}`}/>
            </css.subMenu>
        </css.subMenuBackground>

    );
}

export default SubMenu;
