import * as css from './TicketsGivechak.css';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Table from '../../../../../../component/newTable/Table';
import {useState} from 'react';
import Tickets from '../Tickets';
import GivechakChart from '../../../ticketsChart/GivechakChart';
import TicketsTableHeaderGivechak from '../ticketsTableHeaderGivechak/TicketsTableHeaderGivechak';
import TicketsRowGivechak from '../ticketsRow/TicketsRowGivechak';

const TicketsGivechak = () => {
    const {t} = useTranslation('realtime');
    const {eid} = useSelector((s: any)=> s.event);
    const {tickets} = useSelector((s:any) => s.tickets);
    const sortTickets   = tickets?.slice().sort((a, b) => b.sold - a.sold);
    let showTickets = sortTickets.length > 4 ? sortTickets.slice(0, 3) : sortTickets;
    const moreTickets = sortTickets.length > 4 ? sortTickets.slice(3, sortTickets.length) : null;
    const [activeIndex, setActiveIndex] = useState(null);

    if(moreTickets){
        const moreTicketsInfo = moreTickets ? moreTickets.reduce((item, ticket) => {
            item.sold += ticket.sold || 0;
            item.soldPrice += ticket.soldPrice || 0;
            item.color = ticket.color;
            item.prices = [...item.prices, ...ticket.prices];
            return item;
        }, {sold: 0, left: 0, soldPrice: 0, color: '', prices: []}) : {sold: 0, left: 0, soldPrice: 0, color: ''};
        moreTicketsInfo.title = t('moreTicketsGivechak');
        moreTicketsInfo.isMoreTickets = true;
        moreTicketsInfo.eid = eid;
        showTickets.push(moreTicketsInfo);
    }
    const totalSold =   tickets.reduce((sum, ticket) => sum + ticket.sold, 0); // כמה נמכרו

    showTickets = showTickets.map(ticket => ({
        ...ticket,
        totalSold: totalSold,
    }));

    return (
        <Tickets>
            <GivechakChart tickets={showTickets} activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
            <css.tableTickets className={'free-color'}>
                <TicketsTableHeaderGivechak />
                <Table
                    list={showTickets?.map((ticket,index) => (
                        {
                            comp: TicketsRowGivechak,
                            props: {ticket,active:activeIndex===index,index,setActiveIndex},
                            key: ticket.tid
                        })
                    )}
                    searchProps={false} checked={undefined} buttonsChildren={undefined}/>
            </css.tableTickets>
        </Tickets>

    );
};

export default TicketsGivechak;