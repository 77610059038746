import styled from 'styled-components';
import {
    BGImageCenter,
    BLACK, DARK_GRAY,
    LIGHT_BLUE, mobileMedia,
    VERY_PALE_BLUE,
    WHITE
} from '../../../../globalVariables/GlobalVariables';
import {ItemContent} from '../../../../component/item/Item.css';
import {arrowBtn} from '../../../../assets/symbols/symbols/Symbols.css';
import zIndex from '../../../../globalVariables/zIndex';
import {ArrowWrap} from '../../../../component/list/List.css';
export const container = styled.div<{ hasOrders?: boolean }>`
    display: flex;
    ${p => p.hasOrders ? 'border-bottom:6px solid rgba(141, 212, 229, 0.6);' : ''};
    border-radius: 15px;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 119px);
    ${mobileMedia}{
        height: calc(100% - 90px);
    }
`;
export const chartContainer = styled.div<{ hasOrders?: boolean,showList:boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: ${p=>p.showList? 'calc(100% - 20px)':'100%'};
    align-items: center;
    @media screen and (max-width:1400px) and (min-width:1000px){
        zoom: 125%;
    }
   
    
    .recharts-dot:hover{
        fill: ${BLACK};
        stroke: ${LIGHT_BLUE};
        stroke-width:5px;
        r:6;
        transition: all 0.5s;
    }

    .recharts-curve.recharts-area-curve{
        stroke-opacity: 0.6;
    }
   
    .recharts-surface{
        border-bottom-right-radius: ${p=>p.hasOrders?10:15}px !important;
        border-bottom-left-radius: ${p=>p.hasOrders?10:15}px !important;
    }
    .recharts-tooltip-wrapper{
        z-index: ${zIndex.baseHigh}!important;
    }
    text{
        transform: rotate(-90deg);
        transform-box: fill-box;
    }
    .recharts-wrapper{
        background: ${p=>p.theme.isDarkMode?DARK_GRAY:WHITE};
        border-radius: ${p=>p.hasOrders?10:15}px;
    }
`;
export const customTooltip = styled.div`
    background-color: ${BLACK};
    border-radius: 3px;
    padding: 3px;
`;
export const tooltipText = styled.div`
    color: ${WHITE};
    font-size: 13px;
    font-weight: 400;
`;

export const list = styled.div`
    height: 20px;
    padding-inline-start: 20px;
    cursor: pointer;
  
    
    ${ArrowWrap},${ItemContent}{
        width: 10px;
    }
`;
export const period = styled.div`
    position: relative;
    padding-inline-end: 20px;
    font-weight: bold;
    background-color: ${VERY_PALE_BLUE};
    padding-inline-start: 4px;
    border-radius: 25px;
    width: max-content;
    &::before {
        position: absolute;
        content: '';
        top: 4px;
        inset-inline-end: 0;
        height: 12px;
        width: 12px;
        ${BGImageCenter(arrowBtn, '5px')};
    }
`;

