import {useTranslation} from 'react-i18next';
import * as css from './form.css';
import {useField} from 'formik';
import Input from '../../../../../component/input/Input';
import DateInput from '../../../../../component/input/dateInput/DateInput';
import TimeInput from '../../../../../component/input/dateInput/TimeInput';
import FullEditImage from '../../../../../component/fullEditImage/FullEditImage';
import NumberInput from '../../../../../component/input/NumberInput';
import {roles} from '../../../../team/Team';
import MemberRoleBtn from '../../../../team/memberRoleBtn/MemberRoleBtn';
import FormikDropzon from '../../../../../component/dropzone/FormikDropzone';
import TextareaInput from '../../../../../component/input/textareaInput/TextareaInput';
import {useSelector} from 'react-redux';
import CheckboxFormik from '../../../../../component/checkboxFormik/CheckboxFormik';
import {getFirstDayInMonth, getTs} from '../../../../../library/dates';
import InputList from '../../../../../component/input/inputList/InputList';
import {useEffect} from 'react';
import ModeBtn from '../../../../../component/modeBtn/ModeBtn';
import TooltipAbsolute from '../../../../../component/tooltip/tooltipAbsolute/TooltipAbsolute';

export const ALL_TEAM_KEY = 'allTeam';
function MessageForm({type: formType}){
    const {t} = useTranslation('realtime');
    const [galleryField,galleryMeta,pictureHelper]=useField('image');
    const [DRoleField,DRoleMeta, DRoleHelper]=useField('distributionRole');
    const [viewCountField,, viewCountHelper]=useField('viewCount');
    const [expirationDateField,,expirationDateHelper]=useField('expirationDate');
    const [,,expirationHourHelper]=useField('expirationHour');
    const inputProps=(name)=>({name,text:t(name),description:t(`${name}Desc`),className:name});
    const timeZone = useSelector((s:any) => s.event.timeZone);
    const hideDate =! expirationDateField.value;
    
    const rolesList = [ALL_TEAM_KEY, ...roles].map(role=>({
        key: role,
        text: t(role),
    }));

    const onRoleChanged = (name, itemKey) => {
        if(itemKey === ALL_TEAM_KEY)
            DRoleHelper.setValue([ALL_TEAM_KEY]);
    };
    const onViewSwitchChanged = (newValue) => {
        viewCountHelper.setValue(newValue? -1: '');
    };
    const handleMemberRoleChange = (role) => {
        DRoleHelper.setValue(DRoleField.value.filter(r => role !== r));
    };
    const handleOnClickDateStatus = (state) => {
        if(state){
            expirationDateHelper.setValue(null);
            expirationHourHelper.setValue(null);
        }
        else {
            expirationDateHelper.setValue(getTs());
        }

    };

    useEffect(()=>{
        if(DRoleField.value?.length > 1 && DRoleField.value?.includes(ALL_TEAM_KEY)) {
            handleMemberRoleChange(ALL_TEAM_KEY);
        }
    },[DRoleField.value]);

    const classes = `${DRoleMeta.touched && (DRoleMeta.error ? ' invalid' : ' valid')}`;
    const imageClasses = `${galleryMeta.touched && (galleryMeta.error ? ' invalid' : ' valid')}`;
    
    return (
        <css.container formType={formType} >
            {formType==='text' && <css.subTitle className='titleTitle'>{t('text')}</css.subTitle>}
            {formType==='text' && <Input icon={false} events={undefined} forwardRef={undefined} {...inputProps('title')}/>}
            {formType==='text' && <TextareaInput icon={false} name={'message'} text={t('megaText')} description={''} events={{}} /> }
            <Input events={undefined} icon={false} forwardRef={undefined}{...inputProps('link')}/>
            {formType==='text' && <Input events={undefined} icon={false} forwardRef={undefined}  {...inputProps('linkText')}/>}
            <Input events={undefined} forwardRef={undefined} icon={false} {...inputProps('officeTitle')}/>
            <css.imageUpload className={imageClasses + ' picture'}>
                <FormikDropzon name={''} text={''} fileTypes={'image/jpeg, image/jpg, image/png'} />
                <FullEditImage  image={galleryField.value} setImage={pictureHelper.setValue} update={undefined} type={undefined} size={undefined} />
                {galleryMeta.touched && galleryMeta.error && (<TooltipAbsolute show={true} text={galleryMeta.error} dataTitleIcon={undefined} icon={undefined} /> )}          
            
            </css.imageUpload> 
            <css.subTitle className='settingsTitle'>
                {t('generalSettings')}
                <ModeBtn className='modeBtn' click={handleOnClickDateStatus} status={hideDate} requestStatus={undefined} dataTitle={undefined} text={t('unlimitedTime')} dataCyName={undefined} />
            </css.subTitle>
            <css.dateTime hide={hideDate} className='publishDateTime'>
                <DateInput className={'date'} name={'publishDate'} text={t('dateHourStart')} descText={undefined} onChange={undefined} readOnly={undefined} isFixed={undefined} icon={false} datePickerProps={{minDate:getFirstDayInMonth(getTs(), timeZone)}} />
                <TimeInput className={'time'} name={'publishHour'} text={''} descText={''} onChange={()=>{}} readOnly={false} isTimestamp={true} isFixed={false} icon={false}/>
               
            </css.dateTime> 
            <css.dateTime hide={hideDate} className='expirationDateTime'>
                <DateInput className={'date'} name={'expirationDate'} text={t('dateHourEnd')} descText={undefined} onChange={undefined} readOnly={undefined} isFixed={undefined} icon={false} datePickerProps={{minDate:getFirstDayInMonth(getTs(), timeZone)}} />
                <TimeInput className={'time'} name={'expirationHour'} text={''} descText={''} onChange={()=>{}} readOnly={false} isTimestamp={true} isFixed={false} icon={false}/>
            </css.dateTime>
            <css.viewCountWrapper className='viewCount' >
                <NumberInput events={undefined} forwardRef={undefined} icon={false} {...inputProps('viewCount')} />
                <ModeBtn className='modeBtn' click={onViewSwitchChanged} status={viewCountField.value === -1} requestStatus={undefined} dataTitle={undefined} text={t('unlimited')} dataCyName={undefined} />
            </css.viewCountWrapper>
            <css.distributions className='distributions'>
                <css.roleSelectInput className={classes}>
                    <InputList onChange={onRoleChanged} multiSelect={true} name='distributionRole' list={rolesList} text={t('userPermission')} isSelectable={true} icon={undefined} className={undefined} valid={undefined} onFocus={undefined} > </InputList>
                    <css.rolesConteiner>{DRoleField.value?.map(role=><MemberRoleBtn onclick={handleMemberRoleChange} key={role} member={{role}} />)}</css.rolesConteiner>
                    {DRoleMeta.touched && DRoleMeta.error && (<TooltipAbsolute show={true} text={DRoleMeta.error} dataTitleIcon={undefined} icon={undefined} /> )}          
                </css.roleSelectInput> 
            </css.distributions>
            <css.howToDisplay>
                <CheckboxFormik name={'watchRequired'} text={t('watchRequired')} className={'watchRequired'} color={undefined} />
                <CheckboxFormik name={'displayInPop'} text={t('displayInPop')} className={'displayInPop'} color={undefined} />
                <CheckboxFormik name={'displayInWindow'} text={t('displayInWindow')} className={'displayInWindow'} color={undefined} />
            </css.howToDisplay>
            <css.design className='design' >
                {/* <css.subTitle>צבע רקע</css.subTitle>
                <css.colors>{!!event.colors && event.colors.map((color:string, index) => <css.color key={index} color={color}/>)}</css.colors>
                <css.subTitle>הגדרות שוליים</css.subTitle>
                <css.margin>
                    <Input events={undefined} forwardRef={undefined} {...inputProps('marginTop')} text={'10'} description={'למעלה'} />
                    <Input events={undefined} forwardRef={undefined} {...inputProps('marginBottom')} text={'10'} description={'למטה'} />
                    <Input events={undefined} forwardRef={undefined} {...inputProps('marginRight')} text={'10'} description={'ימין'} />
                    <Input events={undefined} forwardRef={undefined} {...inputProps('marginBottom')} text={'10'} description={'שמאל'} />
                </css.margin> */}
            </css.design>
        </css.container>
   
    );
}
export default MessageForm;