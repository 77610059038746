import React from 'react';
// eslint-disable-next-line spellcheck/spell-checker
import AppleSignin from 'react-apple-signin-auth';
import {FRONT_DOMAIN, IS_PRODUCTION} from '../../../../config/config.js';
import {useTranslation} from 'react-i18next';

export const isAppleDevice =()=> {
    return /Mac|iPod|iPhone|iPad/.test(navigator.platform);
};

const LoginByApple = ({onSuccess}) => {
    const {t} = useTranslation('login');

    if(!isAppleDevice() || IS_PRODUCTION) {
        return null;
    }

    return (
        <AppleSignin
            onSuccess={onSuccess}
            onError={undefined}
            authOptions={{
                clientId: 'com.tickchak.apple.signin',
                scope: 'email name',
                redirectURI:`${FRONT_DOMAIN}/appleSignIn`,
                state: '',
                nonce: 'nonce',
                usePopup: true
            }}
            uiType="light"
            className="apple-auth-btn"
            buttonExtraChildren={t('loginApple')}
            iconProps={{style: {position: 'absolute', insetInlineStart: '10px'}}}
        />
    );
};

export default LoginByApple;
