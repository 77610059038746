import * as css from './Tickets.css';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import TicketsTemplate from './TicketsTemplate';
import {XHR_LOADING_STATE} from '../../../../../assets/constants/constants';
import {eventTypes} from '../../../eventRealtime/eventTypes';

const Tickets = ({children}) => {
    const {t} = useTranslation('realtime');
    const {eid, type} = useSelector((s: any)=> s.event);
    const {getEventTicketsXHR} = useSelector((s:any) => s.tickets);
    const isLoading = getEventTicketsXHR === XHR_LOADING_STATE;
    const translateTitle = eventTypes[type]?.translateTitle || eventTypes.default.translateTitle;

    if(isLoading)
        return <TicketsTemplate />;

    return (
        <css.container>
            <css.title>{t(`${translateTitle}`)}
                <css.ticketsPage className={'free-color'}>
                    <Link to={`/e/${eid}/tickets`}>
                        {t('ticketsPage')}  
                    </Link>
                </css.ticketsPage>
            </css.title>       
            <css.data>
                {children}
            </css.data>
        </css.container>
    );
};

export default Tickets;