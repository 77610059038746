import request from '../../library/request';
import setGlobalState from '../setGlobalState';
import store from '../../store';
import {getTs} from '../../library/dates';
import {TSystemMessage} from './realtime.type';

const getEid = () => store.getState().event.eid;
// const getUid = () => store.getState().user.uid;

export const getRealtime = async () => {
    const res = await request('realtime.getRealtime', 'get', `/event/${getEid()}/realtime`);
    setGlobalState('realtime.event', {...store.getState().realtime.event, ...res});
};

export const getEventStats = async (eid) => {
    const res = await request('realtime.event.getEventStats', 'get', `/event/${eid}/stats`);
    setGlobalState('realtime.event.eventStats', res);
};
export const getSoldTickets = async (eid) => {
    const res = await request('realtime.event.soldTickets', 'get', `/event/${eid}/soldTicket`);
    setGlobalState('realtime.event.soldTickets',res);
};
export const getStats = async () => {
    const res = await request('realtime.user.getStats', 'get', '/user/stats/');
    console.log(res);
    const eventsTypes= res?.countEventType.filter((el) => ['ticket','area','timetable','course','givechak','frontpage','hub'].includes(el.type));
    console.log(eventsTypes);
    const user = store.getState().realtime.user;
    setGlobalState('realtime.user',{...user, countEvents: res?.countEvents,eventsTypes});
};

export const getRealtimeDesign = async () => {
    const res = await request('realtime.user.getRealtimeDesign', 'get', `/event/${getEid()}/realtimeDesign`);
    setGlobalState('realtime.user.realtimeDesign', res?.realtimeDesign);
};
export const getWeather = async () => {
    const res = await request('realtime.event.weather', 'get', `/event/${getEid()}/weather`);
    setGlobalState('realtime.event.weather', res?.weatherData || null);
};

export const updateRealtimeDesign = async (key, value) => {
    const res = await request('realtime.user.getRealtimeDesign', 'put', `/event/${getEid()}/realtimeDesign`, {key, value});
    setGlobalState('realtime.user.realtimeDesign', res?.realtimeDesign);
};

export const updateRealtimeText = async (text, lang) => {
    const res = await request('realtime.updateRealtimeText', 'put', '/updateRealtimeText', {text, lang});
    setGlobalState('realtime.event.realtimeText', res?.realtimeText);
};

export const getRealtimeText = async () => {
    const res = await request('realtime.getRealtimeText', 'get', '/getRealtimeText');
    setGlobalState('realtime.event.realtimeText', res?.realtimeText);
};

export const createRealtimeMessage = async (newMessage: TSystemMessage) => {
    const res = await request('realtime.createRealtimeMessage', 'post', '/systemMessage', {...newMessage, sendTime: newMessage.sendTime || getTs()});
    // const res = await securityRequest('master','manage event','realtime.createRealtimeMessage', 'post', '/systemMessage', {...newMessage, uid: getUid(), eid: getEid(), sendTime: getTs()});
    if(res?.realtimeMessage) {
        const messages = store.getState()?.realtime.systemMessage;
        if(newMessage?.smid) {
            const indexPop = messages.windowMessages?.findIndex(message => message.smid === newMessage.smid);
            const indexWin = messages.popMessages?.findIndex(message => message.smid === newMessage.smid);
            if(indexPop >= 0)  
                setGlobalState(`realtime.systemMessage.popMessages[${indexPop}]`, res.realtimeMessage);
            if(indexWin >= 0)
                setGlobalState(`realtime.systemMessage.windowMessages[${indexWin}]`, res.realtimeMessage);    
        } else {
            if(res.realtimeMessage.displayInPop)
                setGlobalState('realtime.systemMessage.popMessages',[...messages.popMessages, res.realtimeMessage]);
            if(res.realtimeMessage.displayInWindow)
                setGlobalState('realtime.systemMessage.windowMessages',[...messages.windowMessages, res.realtimeMessage]);

        }
    }
    return res;
};
export const getRealtimeMessages = async () => {
    const res = await request('realtime.getSystemMessage', 'get', `/event/${getEid()}/systemMessage`);
    if(res)
        setGlobalState('realtime.systemMessage', res || {});
};
export const getRealtimeMessageById = async (messageId) => {
    const res = await request('realtime.getSystemMessageEdit', 'get', `/systemMessage/${messageId}`);
    if(res)
        setGlobalState('realtime.systemMessageEdit', res);
};
export const getLastsRealtimeMessages = async () => {
    const res = await request('realtime.getLastsRealtimeMessages', 'get', '/systemMessagesLast');
    if(res)
        setGlobalState('realtime.lastsRealtimeMessages', res);
};
export const approveSystemMessage = async (messageId) => {
    const res = await request('realtime.postApproveMessage', 'post', `/event/${getEid()}/approveSystemMessage`, {messageId});
    if(res?.response){
        const messages = store.getState()?.realtime.systemMessage;
        setGlobalState('realtime.systemMessage.popMessages', messages.popMessages.filter(m => m.smid !== messageId));
    }
};