const Ex = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="7"
        height="8"
        fill="none"
        viewBox="0 0 7 8"
    >
        <g
            stroke="#D32F2F"
            strokeLinecap="round"
            strokeLinejoin="round"
            clipPath="url(#clip0_5722_1747)"
        >
            <path d="M6.672.286.54 7.21M.54.286 6.672 7.21" />
        </g>
        <defs>
            <clipPath id="clip0_5722_1747">
                <path fill="#fff" d="M.304.02h6.603v7.457H.304z" />
            </clipPath>
        </defs>
    </svg>
);

export default Ex;
