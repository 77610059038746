import NewEventForm from '../../components/newEventForm/NewEventForm';
import * as Yup from 'yup';
import {newEventInitialValues,newEventSteps, newEventValidationSchema} from '../../globalSteps';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

function NewGivechakEvent() {
    const event=useSelector(s=>s.event);
    const {t} = useTranslation(['global', 'newEvent', 'errors', 'layout']);    
    const givechakSteps={
        title:{...newEventSteps.title,texts:{header:'givechakTitleHeader'},compProps:{text:'givechakTitleHeader',description:'givechakDescriptionText'}},
        date:{...newEventSteps.date,texts:{description:'dateTopHeaderGivechakDesc',header:'dateTopHeaderGivechak'}},
        target:{...newEventSteps.target,texts:{header:'targetText'}},
        description:{...newEventSteps.description,texts:{description:'descriptionHeaderGivechakDesc',header:'descriptionHeaderGivechak'}}
    };
    
    const {title, description, date, locale}=newEventInitialValues(event);
    
    const initialValues = {
        title,
        description,
        date,
        locale,
        target: event.target
    };
    const {title: titleValid, description: descriptionValid, locale: localValid, date: dateValid}=newEventValidationSchema(t);

    const initialValidationSchema = Yup.object().shape({
        title: titleValid,
        description: descriptionValid,
        locale: localValid,
        date: dateValid,
        target: Yup.number() .required()
    });

    return (
        <NewEventForm
            initialValues={initialValues}
            validationSchema={initialValidationSchema}
            steps={Object.values(givechakSteps)}
        />
    );
}

export default NewGivechakEvent;
