import styled from 'styled-components';
import {BGImageCenter, mobileMedia, scrollbar} from '../../../../../globalVariables/GlobalVariables';
import {getColor} from '../../../eventRealtime/EventRealtime.css';
import realtimeFonts from '../../../fonts';
import {text, title} from '../../realtimeDetail/RealtimeDetails.css';
import {simpleArrow} from '../../../../../assets/symbols/symbols/Symbols.css';

export const wrapper = styled.div`
    position: relative;
    padding: 20px;
    display: flex;
    width: 100%;
    ${mobileMedia}{
      flex-direction: column;
      gap: 20px;
    }
`;
export const wrap = styled.div`
  width: 160px;
  ${mobileMedia}{
      width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
`;
export const firstSection = styled.div``;

export const sectionTitle = styled(title)``;

export const data = styled.div`
  position: relative;
    display: flex;
    justify-content: space-between;
    width: calc(96vw - 40px);
    overflow: auto;
    ${scrollbar}
`;
export const currency = styled.span`
    font-size: 14px;
`;

export const amount = styled.div`
  ${realtimeFonts.value};
  margin-bottom: 10px;
`;

export const subtext = styled.div`
  ${realtimeFonts.subText};
  color: ${p=>getColor(p.theme.isDarkMode)};
`;

export const list = styled(text)<{ isOpen?: boolean }>`
  cursor: pointer;
  text-align: start;
  width: 110px;
  margin-block-start: 10px;
  display: flex;
  justify-content: space-between;
  &::after{
    content: '';
    display: block;
    position: relative;
    height: 20px;
    width: 20px;
    transform: ${p=>p.isOpen ? 'rotate(180deg)' : 'rotate(90deg)'};
    ${BGImageCenter(simpleArrow, '10px')}
  }
  ${mobileMedia}{
      margin-block-start: -10px;
  }
`;