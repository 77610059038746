import {Field, useField} from 'formik';
import setGlobalState from '../../../../redux/setGlobalState';
import * as css from './LoginInput.css';

function LoginInput({name, text = '', description = '',type='text', disabled}) {
    const [field, meta,helper] = useField(name);

    return (
        <css.input className={disabled&&'disabled'} data-cy ={`${name}Input`}>
            <css.validateSign
                className={meta?.touched && (meta?.error ? 'invalid' : (field.value&&' valid'))}
                data-cy = 'validateSignLogin'
            />
            <Field
                disabled={disabled}
                type={type}
                name={name}
                onFocus={()=>setGlobalState('ui.isKeyboardOpen',true)}
                onBlur={()=>{
                    helper.setTouched(true);
                    setGlobalState('ui.isKeyboardOpen',false);
                }}
            />
            {meta.error&&meta.touched&&<css.errorMessage data-cy='errMsgLoginSignUp'>{meta.error}</css.errorMessage>}
            <css.text className={!field.value && ' empty '} description={meta.error && meta.touched?'':description}>
                {meta.error&&meta.value?'':text}
            </css.text>
        </css.input>
    );
}

export default LoginInput;