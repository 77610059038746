import styled from 'styled-components';
import {mobileMedia} from '../../../../../globalVariables/GlobalVariables';

export const container = styled.div`
`;
export const filterWrapper = styled.div`
        position: absolute;
        top: 90px;
        inset-inline-end: 80px;
        ${mobileMedia} {
            top: 115px;
            inset-inline-end: 20px;
        }
`;
export const subTitle = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    letter-spacing: 1.5px;
    font-weight: 400;
    font-size: 14px;
`;