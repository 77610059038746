import {search} from '../assets/symbols/symbols/Symbols.css';
import styled from 'styled-components';
import * as symbol from '../assets/symbols/symbols/Symbols.css';
import zIndex from './zIndex';

export const DEFAULT_COLOR='#76D6E8';
export const YELLOW='#FFEB00';
export const YELLOW_ORANGE='#FFBF00';
export const PERSIMMON='#F55500';
export const TANGERINE='#F58400';
export const GOLDEN_POPPY='#f5c600';
export const INCH_WORM='#a8dd1b';
export const LIGHT_GREEN='#C6D628';
export const GREEN='#77d154';
export const GRAY='#d7d7d7';
export const DARK_GRAY='#484848';
export const VERY_DARK_GRAY='#707070';
export const LIGHT_GRAY='#F8F7F7';
export const LIGHT_GRAY2='#F0F0F0';
export const LIGHT_GRAY3='#AEAEAE';
export const LIGHT_GRAY4='#D8D8D8';
export const LIGHT_GRAY5='#E0E0E0';
export const GRAY92 = '#EBEBEB';
export const OLIVE_GREEN='#C6D600';
export const VERY_PALE_BLUE='#F0FBFF';
export const STRONG_BLUE='#43BAE7';
export const TURQUISE_BLUE='#76D6E8';
export const MATTE_LIGHT_BLUE='#71C3CF';
export const ROYAL_BLUE='#467CF2';
export const BLACK='#161616';
export const RED='#ff0000';
export const PERSIAN_RED='#D32F2F';
export const WHITE='#FFFFFF';
export const WHITE2='#FAFAFA';
export const LIGHT_BROWN='#E5DDD5';
export const DARK_GREEN='#366962';
export const LIGHT_BLUE = '#8DD4E5';
export const SHADOW_COLOR='#00000029';
export const LIGHT_SHADOW_COLOR='#CAC6C640';
export const LIGHT_BLUE2 = '#FAFDFF';
export const SKY_BLUE = '#29ACE3';
export const VERY_LIGHT_GRAY = '#DDD';
export const DARK_OLIVE_GREEN = '#C6D60033';
export const LIGHT_BLUE3 = '#76D6E8';
export const LIGHT_BLUE_GRAY = '#E8F1F5';
export const DARK_GRAY2 = '#A8A7A7';
export const SUCCESS_COLOR = '#AED11C';
export const FAILED_RED = '#EA3323';
export const DARK_RED = '#C2002B';
export const DARK_GRAY1 = '#3C4043';
export const mobileScreen = 800;
export const tabletScreen = 1000;
export const mobileMedia = ` @media screen and (max-width:${mobileScreen}px) `;
export const tabletMedia = ` @media screen and (max-width:${tabletScreen}px) `;
export const laptopMedia = ` @media screen and (min-width:${tabletScreen}px) and (max-width:1500px) `;
export const tabletPlusMedia = ` @media screen and (min-width:${mobileScreen}px) `;
export const desktopMedia = ` @media screen and (min-width:${mobileScreen}px) `;
export const resolveImage=(stringUrl='')=> {
    if(typeof stringUrl === 'string' || stringUrl instanceof String)
        return stringUrl.replace(/\(/g,'%28').replace(/\)/g,'%29');
    return stringUrl;
};        // return stringUrl.replaceAll(/\(/g, '\\u0028').replaceAll(/\)/g, '\\u0029');

export const POPUP_DURATION=600;
export const BUTTON_HEIGHT=30;

export const dFlexCenter = `
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    `;

export const BGImageCenter = (url, size = '10px') => `
    background-image: url("${resolveImage(url)}");
    background-size: ${size};
    background-repeat:no-repeat;  
    background-position:center center; 
    `;

export const scrollbar = `
    overflow: auto;
    &::-webkit-scrollbar {
        width: 0px;
        height: 0;
    }
`;

export const ellipsis = `
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const ellipsisLines = (n) => `
    display: -webkit-box;
    -webkit-line-clamp: ${n};
    line-clamp:${n};
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

export const searchResult = (isSearchResult = false) => isSearchResult ? `
    background: #d0eff540;
    border-radius: 7px;
    border: 1px solid #76d6e861;
    &::after{
        content:"";
        ${BGImageCenter(search, '10px')}
        width: 10px;
        position: absolute;
        height: 10px;
        left: 3px;
        top: 4px;
    
    }
    
    `: '';

export const popupScreen = `
    backdrop-filter: blur(8px) brightness(0.6);
    -webkit-backdrop-filter: blur(8px) brightness(0.6);
    position:fixed;
    width:100%;    
    top:0;
    left:0;
    z-index:${zIndex.basicModal};    
    height:100%;
    opacity:0;
    &.show{
        opacity:1;
    }
   transition: all 500ms;

`;

export const boxShadow = `
    box-shadow: rgb(45 45 45 / 5%) 0 2px 2px, rgb(49 49 49 / 5%) 0 4px 4px, rgb(42 42 42 / 5%) 0 8px 8px, rgb(32 32 32 / 5%) 0 16px 16px, rgb(49 49 49 / 5%) 0 32px 32px, rgb(35 35 35 / 5%) 0 64px 64px;
    -webkit-box-shadow: rgb(45 45 45 / 5%) 0 2px 2px, rgb(49 49 49 / 5%) 0 4px 4px, rgb(42 42 42 / 5%) 0 8px 8px, rgb(32 32 32 / 5%) 0 16px 16px, rgb(49 49 49 / 5%) 0 32px 32px, rgb(35 35 35 / 5%) 0 64px 64px;
    -moz-box-shadow: rgba(45,45,45,.05) 0 2px 2px,rgba(49,49,49,.05) 0 4px 4px,rgba(42,42,42,.05) 0 8px 8px,rgba(32,32,32,.05) 0 16px 16px,rgba(49,49,49,.05) 0 32px 32px,rgba(35,35,35,.05) 0 64px 64px;

    `;

export const options = styled.div`
    cursor: pointer;
    width: 20px;
    height: 10px;
    ${BGImageCenter(symbol['op'], '8px')}
    transition:all 300ms;
    &:hover{
        //transform: scaleX(3.5);
    }
`;

export const white = styled.div`
    color: #FFFFFF;
`;

export const getStaticImage=(image)=>`https://static.tickchak.co.il/images/${image}`;