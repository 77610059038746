import * as css from './TicketsTicket.css';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Table from '../../../../../../component/newTable/Table';
import useMobile from '../../../../../../hooks/useMobile';
import mobileTicketsRow from '../ticketsRow/mobileTicketsRow/MobileTicketsRow';
import TicketsTableHeader from '../ticketsTableHeader/TicketsTableHeader';
import TicketsChart from '../../../ticketsChart/TicketsChart';
import {useState} from 'react';
import Tickets from '../Tickets';
import TicketRowTicket, {getDiscount} from '../ticketsRow/TicketRowTicket';

const TicketsTicket = () => {
    const {t} = useTranslation('realtime');
    const {eid} = useSelector((s: any)=> s.event);
    const {tickets} = useSelector((s:any) => s.tickets);
    const isMobile = useMobile();
    const sortTickets   = tickets?.slice().sort((a, b) => b.sold - a.sold);
    const showTickets = sortTickets.length > 4 ? sortTickets.slice(0, 3) : sortTickets;
    const moreTickets = sortTickets.length > 4 ? sortTickets.slice(3, sortTickets.length) : null;
    const [activeIndex, setActiveIndex] = useState(null);

    if(moreTickets){
        const moreTicketsInfo = moreTickets ? moreTickets.reduce((item, ticket) => {
            item.amount += ticket.amount;
            item.totalPrice += ((ticket.amount ?? 0) * (ticket.price ?? 0 ));
            item.unsoldPrice += (((ticket.amount ?? 0) - (ticket.sold ?? 0)) * (ticket.price ?? 0 ));
            item.sold += ticket.sold || 0;
            item.soldPrice += ticket.soldPrice || 0;
            item.color = ticket.color;
            item.prices = [...item.prices, ...ticket.prices];
            item.discount += getDiscount(ticket) || 0;
            return item;
        }, {amount: 0, totalPrice:0, unsoldPrice:0, sold: 0, left: 0, soldPrice: 0, color: '', prices: [], discount: 0}) : {amount: 0, totalPrice:0, unsoldPrice:0, sold: 0, left: 0, soldPrice: 0, color: '', price: [], discount: 0};
        moreTicketsInfo.title = t('moreTickets');
        moreTicketsInfo.isMoreTickets = true;
        moreTicketsInfo.eid = eid;
        showTickets.push(moreTicketsInfo);
    }

    return (
        <Tickets>
            <TicketsChart tickets={showTickets} activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
            <css.tableTickets className={'free-color'}>
                <TicketsTableHeader />
                <Table
                    list={showTickets?.map((ticket,index) => (
                        {
                            comp: isMobile ? mobileTicketsRow : TicketRowTicket,
                            props: {ticket,active:activeIndex===index,index,setActiveIndex},
                            key: ticket.tid
                        })
                    )}
                    searchProps={false} checked={undefined} buttonsChildren={undefined}/>
            </css.tableTickets>
        </Tickets>

    );
};

export default TicketsTicket;