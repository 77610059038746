import {resetPassword, updatePassword} from '../../../../redux/user/userActions';
import * as css from './ForgotPassword.css';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';
import {useHistory} from 'react-router-dom';
import CreatePassword from '../../components/createPassword/CreatePassword';
import {useState} from 'react';
import EnterButton from '../../components/enterButton/EnterButton';
import SMSCode from '../../../../component/SMSCode/SMSCode';
import {useSelector} from 'react-redux';
import {XHR_SUCCESS_STATE} from '../../../../assets/constants/constants';
import {useSearchParams} from '../../../../hooks/useSearchParams';
import LoginButton from '../../components/loginButton/LoginButton';

function ForgotPassword({setCurrentPage}) {
    const {t} = useTranslation('login');
    const [emailField,, emailHelper] = useField('email');
    const [passwordField1] = useField('password');
    const [passwordField2] = useField('password2');
    const [loginState, setLoginState] = useState(true);
    const resetPasswordSuccess = useSelector(s=>s.user.resetPasswordXHR === XHR_SUCCESS_STATE);
    const updatePasswordSuccess = useSelector(s=>s.user.updatePasswordXHR === XHR_SUCCESS_STATE);
    const errorMessage=useSelector(s=>s.auth.message);
    const {token, email} = useSearchParams();
    const originalEmail = email?Buffer.from(email, 'base64').toString('utf8'):'';
    const history = useHistory();
    const emailSent = useSelector(state => state.user.email?.replaceAll('0','X'));

    if(originalEmail && emailField.value !== originalEmail) {
        emailHelper.setValue(originalEmail);
    }

    const resendCode = ()=>{
        resetPassword(originalEmail, passwordField1.value, passwordField2.value, token);
    };
    const onCodeUpdate = async (code) => {
        await updatePassword(emailField.value, passwordField1.value, passwordField2.value, token, code);
    };

    const backToLogin = () => {
        history.push('/login');
        setCurrentPage('signIn');
    };
    return (
        <css.container>
            <css.title>{t('forgotPasswordEmail')}</css.title>
            <css.inputs>
                <css.details>
                    <css.text>{t('emailAccount')}</css.text>
                    <css.email>{originalEmail}</css.email>
                </css.details>
                {(resetPasswordSuccess && !updatePasswordSuccess) ? <SMSCode sendAgainClick={resendCode} onUpdate={onCodeUpdate} text={t('codeEmailSent')} address={emailSent}/> 
                    :  <CreatePassword loginState={setLoginState} />}
                {updatePasswordSuccess && <css.email>{t('updatePasswordSuccess')}</css.email>}
            </css.inputs>
            <css.enterButton>
                {!resetPasswordSuccess && <EnterButton errorText={t(errorMessage)} actionName="resetPassword" buttonType="resetPassword" disabled={loginState} text={t('changePassword')} />}
                {updatePasswordSuccess && <LoginButton onClick={backToLogin} text={t('connect')} />}
            </css.enterButton>
        </css.container>
    );
}

export default ForgotPassword;
