type TValidationDefinition = {
    name: string;
    text: string;
    regex: RegExp | ((password: string, lastPasswords?: string[], personalInfo?: any) => boolean);
}

let commonPasswords = new Set();
loadCommonPasswords();
// read list from file
function loadCommonPasswords () {    
    fetch('/commonPasswords.txt')
        .then(res =>res.text())
        .then(fileContent=>{
            commonPasswords = new Set(fileContent.split('\n').map(password => password.trim()));
        }).catch ((error)=> {
            console.error('read file error', error);
            commonPasswords = new Set();
        });
}
const checkCommonPasswords = (password) => {
    // check common passwords    
    return !commonPasswords.has(password);
};

const checkPersonalInfo = (password, nothing, personalInfo) => {
    let containsPersonalInfo = false;
    if(personalInfo) {
        const {userName, phoneNumber, email} = personalInfo;
        // check for user name
        if(password.toLowerCase().includes(userName.toLowerCase())) {
            containsPersonalInfo = true;
        }

        // check for phone number
        if((phoneNumber && password.includes(phoneNumber))) {
            containsPersonalInfo = true;
        }

        // check for email or part of the email
        if((email && password.toLowerCase().includes(email.toLowerCase().split('@')[0]))) {
            containsPersonalInfo = true;
        }

        return !containsPersonalInfo;
    }
    return true;
};

const checkLastPasswords = (password, lastPasswords) => {
    return !lastPasswords.includes(password);
};

function validatePassword (password, lastPasswords = null, personalInfo = null) {
    let strengthPassword = 0;

    const initialDetailsState: TValidationDefinition[] = [{name: 'minLength', text: 'minLength', regex: /^.{8,}$/},
        {name: 'maxLength', text: 'maxLength', regex: /^.{1,24}$/},
        {name: 'uppercaseLetter', text: 'uppercase', regex: /[A-Z]+/},
        {name: 'lowercaseLetter', text: 'lowercase', regex: /[a-z]+/},
        {name: 'symbol', text: 'symbol', regex: /[$@#-/:-?{-~!"^_`\[\]]+/},
        {name: 'numbers', text: 'digits', regex: /[0-9]+/},
        {name: 'common', text: 'commonPasswords', regex: checkCommonPasswords}
    ];
    if(personalInfo)initialDetailsState.push({name: 'personal', text: 'personalInfo', regex: checkPersonalInfo});
    if(lastPasswords)initialDetailsState.push({name: 'lasts', text: 'lastPasswords', regex: checkLastPasswords});

    const validationStatus = {};
    strengthPassword += initialDetailsState.reduce((strength, rule) => {
        let match;
        if(typeof rule.regex === 'function') {
            match = rule.regex(password, lastPasswords, personalInfo);
        } else {
            match = rule.regex.test(password);
        }
        validationStatus[rule.name] = {isValid: match, text: rule.text};
        return match ? strength + 1 : strength;
    }, 0);

    return {
        strengthPassword,
        validationsAmount: initialDetailsState.length,
        validationStatus,
        isValid: strengthPassword === initialDetailsState.length
    };
}
export default validatePassword;

