import styled from 'styled-components';
import * as title from '../../component/title/Title.css';
import * as symbol from '../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter} from '../../globalVariables/GlobalVariables';
import {unverifiedAccount} from './packageSettings/PackageSettings.css';
import {tickchakImage} from '../../assets/symbols/images/tickchakImage';
import {text} from '../../component/button/btnAddEvent/BtnAddEvent.css';
import zIndex from '../../globalVariables/zIndex';

export const TopMenu = styled.div`
    height: 50px;
    width: 100%;
    display: flex;
    position:relative;
    box-shadow: rgba(190,190,190,.05) 0 2px 2px, rgba(170,170,170,.05) 0 4px 4px, rgba(170,170,170,.05) 0 8px 8px, rgba(65,65,65,.05) 0 16px 16px, rgba(70,70,70,.05) 0 32px 32px, rgba(80,80,80,.05) 0 64px 64px;
    -webkit-box-shadow: rgba(190,190,190,.05) 0 2px 2px, rgba(170,170,170,.05) 0 4px 4px, rgba(170,170,170,.05) 0 8px 8px, rgba(65,65,65,.05) 0 16px 16px, rgba(70,70,70,.05) 0 32px 32px, rgba(80,80,80,.05) 0 64px 64px;
    -moz-box-shadow: rgba(190,190,190,.05) 0 2px 2px, rgba(170,170,170,.05) 0 4px 4px, rgba(170,170,170,.05) 0 8px 8px, rgba(65,65,65,.05) 0 16px 16px, rgba(70,70,70,.05) 0 32px 32px, rgba(80,80,80,.05) 0 64px 64px;  
`;
const Select = styled.div`
    display:flex;
    cursor: pointer;    
    ${props => (props.theme.rtl ? 'border-right:1px solid #cccccc;' : 'border-left:1px solid #cccccc;')};
    position:relative;
    height:100%;

    @media screen and (max-width: 1000px){  
        border:0;
    }       
    &::after{
        content:'';
        position:absolute;
        width:10px;
        height:10px;        
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.344' height='6.434' viewBox='0 0 8.344 6.434'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: none; stroke: %23484848; stroke-linecap: round; stroke-width: 2px; %7D %3C/style%3E%3C/defs%3E%3Cpath id='Path_2879' data-name='Path 2879' class='cls-1' d='M233.982,21l2.766,3.434L239.514,21' transform='translate(-232.576 -19.594)'/%3E%3C/svg%3E%0A");
        background-repeat:no-repeat;
        background-size:contain;
        ${props => (props.theme.rtl ? 'left' : 'right')}:15px;
        top: calc(50% - 5px);
        background-position: center center; 
        opacity:0;
    }
`;
const SelectText = styled.div`
    height:100%;
    display: flex;
    display: -ms-flexbox;
    justify-content: center;
    align-items: center;
    font-size: 18px;

   
`;
export const SelectEvents = styled(Select)`
    width:265px;
    font-weight:900;
    justify-content: center;
    padding-${props => props.theme.rtl ? 'left' : 'right'}: 15px;
    @media screen and (max-width: 1000px) {
        width:60px;
        display:block;
        padding:0;
    }
    &::after{
        opacity: 1;
        @media screen and (max-width: 1000px){
            width: 8px;
            height: 8px;
            left:auto;            
            ${props => (props.theme.rtl ? 'right:2px' : 'left:5px')};
        }
    }
`;
export const SelectEventsIcon = styled.div`
    display:none;
    ${BGImageCenter(symbol.symbolEventDark.event)}
    position:relative;
    @media screen and (max-width: 1000px) {
       display:block;
       width:100%;
       background-size: 70% 70%;
       background-position: center bottom;
       height: 35px;
    }
`;
export const SelectEventsSearch = styled.div`
   width:40px;
   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.212' height='17.209' viewBox='0 0 17.212 17.209'%3E%3Cpath id='Path_2881' data-name='Path 2881' d='M122.367,777.314l-3.116-3.112a7.836,7.836,0,0,0,1.819-5.024,7.678,7.678,0,0,0-.585-2.959l-.013-.042a7.978,7.978,0,0,0-1.662-2.51l-.042-.038a7.806,7.806,0,0,0-2.548-1.7h0a7.852,7.852,0,0,0-10.853,7.25,7.686,7.686,0,0,0,.585,2.968l.009.038a7.939,7.939,0,0,0,1.708,2.552h0a7.9,7.9,0,0,0,2.548,1.7h0a7.84,7.84,0,0,0,8.017-1.217l3.116,3.112a.719.719,0,0,0,1.017-1.017Zm-4.612-3.6h0l0,.013a6.216,6.216,0,0,1-2.077,1.382,6.349,6.349,0,0,1-2.45.483,6.424,6.424,0,0,1-2.45-.479v0a6.283,6.283,0,0,1-2.086-1.4h0a6.493,6.493,0,0,1-1.391-2.082l-.013-.034a6.459,6.459,0,0,1,.013-4.871,6.688,6.688,0,0,1,1.391-2.082,6.457,6.457,0,0,1,4.536-1.865,6.354,6.354,0,0,1,2.455.479h0a6.374,6.374,0,0,1,2.082,1.386l.03.038a6.424,6.424,0,0,1,1.365,2.043l.021.038a6.5,6.5,0,0,1,.458,2.412,6.258,6.258,0,0,1-.479,2.455,6.339,6.339,0,0,1-1.391,2.082Z' transform='translate(-105.367 -761.336)'/%3E%3C/svg%3E%0A");
   background-repeat:no-repeat;
  background-position:center center;
   @media screen and (max-width: 1000px) {
       display:none;
   }
`;

export const SelectTextEvents = styled(SelectText)`
    width:120px;
    font-size: 16px;
    font-weight: 500;
    @media screen and (max-width: 1000px) {
        display:block;
        width: 100%;
        width:100%;
        height:20px;      
        font-size: 9px;
        font-weight: 800;
        letter-spacing: -0.5px; 
        text-align: center;   
    }   
`;
export const SelectEventsCount = styled.div`
    width:35px;
    height:15px;
    background:#C9E52C;
    border-radius:40px;
    margin-top: 19px;
    font-size: 10px;
    font-weight: 800;
    text-align: center;
    line-height: 15px;
    @media screen and (max-width: 1000px) {
        display:none;
    }
`;

export const SelectPackage = styled(Select)`
    width:180px;
    border:unset;
    &:hover{
        &::after{
                opacity:1 ;
            }
        }
`;
export const SelectTextPackageTitle = styled.div`
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    right:0;
`;
export const SelectTextPackage = styled.div`
    height:100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 50px;
    font-size: 18px;
    width:100%;
    max-width: 150px;
    text-align: center;
    font-weight:500;
    position: relative;
    padding: ${props => (props.theme.rtl ? '0 5px 0 5px' : '0 5px 0 5px')};
        font-size: 16px;

`;
export const SelectMenu = styled.div`
     @media screen and (max-width: 1000px) {
            ${BGImageCenter(symbol.menu,'18px')}
            &.packageIcon{
                ${BGImageCenter(symbol.packageImage,'18px')}
            }
            width:20px;
           height:20px;
           width: 50px;
           font-size: 0;
           height: 100%;
           position:unset;
           user-select:none;
           display: flex;
           align-items: center;
           justify-content: center;
    }
    &::after{
        opacity: 1;
        @media screen and (max-width: 1000px){
             content:unset;
        }
    }
    
    
`;

const TopMenueChild = styled.div`
    height:100%;
    width:50px;
    background-repeat:no-repeat;
    background-position:center center;
`;

export const TopMenuLogo = styled(TopMenueChild)`
     background-image: url("${tickchakImage}");
     width:65px;
     z-index: ${zIndex.baseRaised};
     border-inline-end: 1px solid #CCC;
     @media screen and (max-width: 1000px) {
        width:100%;
     }
   `;

export const MailingComponent = styled.div`
    margin: 21px;
    display: flex;
    position: absolute;
    right: 35%;
    opacity: 0.015;
    @media screen and (max-width: 1000px){  
        right:40px;
    } 
    .btn{
        background-repeat: no-repeat;
        background-size: 20px auto;
        height: 20px;
        width: 20px;
        background-position: center center;
        margin-right: 15px;
        cursor: pointer;
    }
    
`;
export const MailingBtn = styled.div`
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.322' height='11.965' viewBox='0 0 15.322 11.965'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%233a4143;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M257.459,755.317H271.6a.6.6,0,0,1,.6.591v10.779a.591.591,0,0,1-.6.6H257.459a.589.589,0,0,1-.582-.6V755.908a.593.593,0,0,1,.582-.591Zm13.558,1.7h0l-6.235,6.231a.354.354,0,0,1-.492,0l-6.235-6.231V766.1h12.963v-9.091Zm-.484-.514h0l-12,0,6,6,5.991-6Z' transform='translate(-256.877 -755.317)'/%3E%3C/svg%3E");
`;
export const SmsBtn = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.789' height='14.797' viewBox='0 0 14.789 14.797'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%233a4143;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M270.6,754.907a7.373,7.373,0,0,1,5.231,2.167l.029.033a7.4,7.4,0,0,1,1.77,7.489,7.277,7.277,0,0,1-.943,1.911v2.626a.578.578,0,0,1-.575.571H270.6a7.408,7.408,0,0,1-5.232-2.171h0a7.4,7.4,0,0,1,0-10.459h0a7.387,7.387,0,0,1,5.232-2.167Zm-3.685,5.914h7.37a.336.336,0,0,0,.343-.339.342.342,0,0,0-.343-.339h-7.37a.342.342,0,0,0-.343.339.336.336,0,0,0,.343.339Zm0,1.824h7.37a.339.339,0,1,0,0-.678h-7.37a.339.339,0,1,0,0,.678Zm0,1.82h7.37a.342.342,0,0,0,.343-.339.336.336,0,0,0-.343-.339h-7.37a.336.336,0,0,0-.343.339.342.342,0,0,0,.343.339Zm8.106-6.584h0a6.25,6.25,0,0,0-8.85,0h0a6.27,6.27,0,0,0,0,8.85h0a6.237,6.237,0,0,0,4.429,1.832h4.942v-2.237a.559.559,0,0,1,.116-.335,6.442,6.442,0,0,0,.9-1.741,6.54,6.54,0,0,0,.3-1.939,6.257,6.257,0,0,0-1.8-4.409l-.033-.021Z' transform='translate(-263.204 -754.907)'/%3E%3C/svg%3E");

`;

export const unverified = styled(unverifiedAccount)`
`;
const PLUS_BTN_WIDTH=40;

export const right=styled.div`
    flex-basis: calc(50% - ${PLUS_BTN_WIDTH/2}px);
    display: flex;
    justify-content: flex-start;
`;

export const left=styled.div`
    flex-basis: calc(50% - ${PLUS_BTN_WIDTH/2}px);
    display: flex;
    justify-content: flex-end;
`;
export const AddEvent = styled.div`
    flex-basis: ${PLUS_BTN_WIDTH}px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${text}{
      font-size: 16px;
      font-weight: 500;
      height: max-content;
      pointer-events: none;
}

`;