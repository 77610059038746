import createReducer from '../createReducer';
import {TCashStats} from './cash.type';

export const initialStateCash:TCashStats = {
    eventList:[],
    dateList:[],
    getEventListXHR:null,
    getDateListXHR:null
};

const reducer = createReducer('cash',initialStateCash);

export default reducer;