import * as css from './PageWrapper.css';
import SubMenu from '../subMenu/SubMenu';
import {createContext, useState} from 'react';
import RightHeader from '../../../component/rightHeader/RightHeader';
import {useTranslation} from 'react-i18next';
import LeftPageDefault from './leftPageDefault/LeftPageDefault';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

export const PageWrapperContext = createContext();

function PageWrapper({image, operations, children ,leftTitle, className, SubMenuChildren,subMenuProps, routing, mobileActions}) {
    const [openLeft,setOpenLeft]=useState('');
    const {t}=useTranslation('layout');

    const {width}=useWindowDimensions();
    const isAlwaysLeftOpen=(width>css.BIG_SCREEN_SIZE)||
    (width<css.MIDDLE_SCREEN_SIZE&&width>css.SMALL_SCREEN_SIZE);

    return (
    
        <PageWrapperContext.Provider value={{operations,title:leftTitle}}>
            <SubMenu image={image} className={className} openLeft={openLeft} routing={routing} subMenuProps={subMenuProps} mobileActions={mobileActions}>
                {SubMenuChildren}
            </SubMenu>
            <css.pageFrame>
                <css.right className={openLeft}>
                    {Array.isArray(children) ? children[0] : children}
                </css.right>{
                    <css.left className={openLeft} >
                        <css.leftTitle className={openLeft}  onClick={()=>setOpenLeft('openLeft')}>
                            <css.leftTitleText>{leftTitle||t`soon`}</css.leftTitleText>
                        </css.leftTitle>
                        <RightHeader
                            icons={<css.closeLeft  className={openLeft} onClick={()=>setOpenLeft('')}/>    }>
                            {leftTitle}
                        </RightHeader>
                        {(isAlwaysLeftOpen||openLeft)?(children[1]||<LeftPageDefault/>):<></>}
                    </css.left>}
            </css.pageFrame >
        </PageWrapperContext.Provider>
        
    );
}

export default PageWrapper;
