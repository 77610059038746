import {useEffect, useMemo} from 'react';
import ProgressBarThin from '../../../../component/progressBarThin/ProgressBarThin';
import * as css from './CreatePassword.css';
import {useTranslation} from 'react-i18next';
import LoginPasswordInput from '../loginPasswordInput/LoginPasswordInput';
import {useField} from 'formik';
import SettingsTooltip from './settingsTooltip';
import validatePassword from '../../signIn/forgotPassword/validatePassword';
import {useSelector} from 'react-redux';
import {XHR_SUCCESS_STATE} from '../../../../assets/constants/constants';

function CreatePassword({loginState}) {
    const {t} = useTranslation('login');
    const [passwordField1] = useField('password');
    const [passwordField2] = useField('password2');
    const updatePasswordSuccess = useSelector((s: any)=>s.user.updatePasswordXHR === XHR_SUCCESS_STATE);
    const {strengthPassword, validationStatus, isValid, validationsAmount} = useMemo(() => validatePassword(passwordField1.value), [passwordField1.value]);
    
    const isFieldsValid = passwordField1.value === passwordField2.value && isValid;

    useEffect(() => {
        loginState(!isFieldsValid);
    }, [isFieldsValid]);

    return (
        <css.container>
            <LoginPasswordInput name="password"  text={t('password')} description={t('')} toEmptyOnFocus disabled={updatePasswordSuccess} />
            {passwordField1.value && <ProgressBarThin amount={validationsAmount} progress={strengthPassword}/>}    
            <SettingsTooltip tooltipObj={validationStatus} display={!!passwordField1.value && validationsAmount!==strengthPassword}  />
            <LoginPasswordInput disabled={validationsAmount!==strengthPassword || updatePasswordSuccess}  name="password2"  text={t('password')} description={t('')} toEmptyOnFocus/>
        </css.container>
    );
}

export default CreatePassword;
