import styled from 'styled-components';
import {ellipsis, mobileMedia} from '../../../../../globalVariables/GlobalVariables';
import {checkbox} from '../../../../../component/checkbox/Checkbox.css';

export const AccessibilityContain= styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 35%;
    inset-inline-end: 45px;
    align-items: center;
    ${ellipsis}
    svg{
        height: 14px;
        width: 14px;
        margin-inline: 5px;
        ${mobileMedia}{
            height: 18px;
            width: 18px;
            margin-inline: 0;
        }
    } 
    ${checkbox}{
        width: 16px;
        height: 16px;
        border-color: ${p=>p.theme.color};
        border-radius: 3px;
        box-sizing: content-box;
        &.selected{
            &::before{
              background: ${p=>p.theme.color};
              height: 8px;
              width: 8px;
              top: calc(50% - 4px);
              right:calc(50% - 4px);
              border-radius: 2px;
            }
        }
        ${mobileMedia}{
            margin: 0;
            margin-inline:10px;
        }
    }
    ${mobileMedia}{
      height: 35px;
      position: unset;
      color: ${p=>p.theme.color};
    }

`;
