import store from '../../store';
import request from '../../library/request';
import setGlobalState from '../setGlobalState';

const getPid = () => store.getState().account.pid;
export const getReportEvents = async () => {
    const res=await request('cash.getEventList', 'get', `/package/${getPid()}/reports/`);
    if(res)
        setGlobalState('cash', {...store.getState().cash, ...res});

};
export const getInvoiceDates = async () => {
    const res=await request('cash.getDateList', 'get', `/package/${getPid()}/invoices/`);

    if(res)
        setGlobalState('cash', {...store.getState().cash, ...res});

};
