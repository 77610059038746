import {useEffect} from 'react';
import * as css from './soldTickets.css';
import {useSelector} from 'react-redux';
import {getSoldTickets} from '../../../../../redux/realtime/realtimeActions';
import {useTranslation} from 'react-i18next';
import {formatRealtimeNumber} from '../../../functions';
import {eventTypes} from '../../../eventRealtime/eventTypes';
import {XHR_LOADING_STATE} from '../../../../../assets/constants/constants';
import SoldTicketsTemplate from './soldTicketTemplate';
import {CircleProgress, Insight, MainDetail} from '../../windowItems/windowItems';

function SoldTickets() {
    const {eid, currencySymbol, type: eventType} = useSelector((s: any)=>s.event);
    const allStats = useSelector((s:any) => s.realtime?.event);
    const stats = allStats.soldTickets;
    const eventConfig = eventTypes[stats?.isScheduled? 'scheduled': eventType]?.ticketsTitle || eventTypes.default.ticketsTitle;
    const {t} = useTranslation('realtime');
    const totalCash = allStats?.eventStats?.all?.totalPrice;
    const isLoading = useSelector((s:any)=>s.realtime?.event?.soldTicketsXHR === XHR_LOADING_STATE);
    
    useEffect(()=>{
        if(eid) getSoldTickets(eid);
    },[eid]);
    
    if(isLoading) {
        return <SoldTicketsTemplate />;
    }
    return ( stats ? <css.container>
        <css.content>
            {eventConfig.soldTitle &&<MainDetail item={{
                title: eventConfig.soldTitle,
                value: stats.sold,
                text: t(eventConfig.amountTitle, {amount: formatRealtimeNumber(stats.amount || 0, true)})
            }} />}
            {eventConfig.leftTitle && <MainDetail item={{
                title: eventConfig.leftTitle,
                value: stats.available,
            }} />}
        </css.content>
        <css.insights>
            <css.insightsNumbers eventType={eventType}>
                <Insight item={{
                    value: stats.process,
                    text: eventConfig.purchaseProcess
                }} />
                <Insight item={{
                    value: stats.free,
                    text: eventConfig.free
                }} />
                <Insight item={{
                    value: stats.close,
                    text: eventConfig.close
                }} />
                <Insight item={{
                    value: stats.saved,
                    text: `${t(eventConfig.saved)} ${stats.savedTo ? t('savedTo',{name: stats.savedTo}):''}`
                }} />
                <Insight item={{
                    value: `${currencySymbol}${formatRealtimeNumber(totalCash/(stats.sold ||1))}`,
                    text: eventConfig.averagePricePerTicket
                }} />
            </css.insightsNumbers>
            <CircleProgress amount={stats.amount} sold={stats.sold} text={'goalText'} />
        </css.insights>
    </css.container>:<></>
    );
}
export default SoldTickets;