import {useTranslation} from 'react-i18next';
import List from '../../../../../component/list/List';
import useShow from '../../../../../hooks/useShow';
import SystemMessagePop from '../systemMessagePop/systemMessagePop';
import * as css from './systemMessageMenu.css';
import {useEffect} from 'react';
import {getLastsRealtimeMessages} from '../../../../../redux/realtime/realtimeActions';
import {useSelector} from 'react-redux';
import {IS_PRODUCTION} from '../../../../../config/config';
import useSystemMessage from '../useSystemMessage';

function SystemMessageMenu() {
    const {t} = useTranslation('realtime');
    const menuShow = useShow();
    const uid = useSelector((s:any) => s.user?.uid);
    const {systemMessagesList, realtimeMessageShow, setShowList} = useSystemMessage();
    const isSystemMessageMasterUser=()=>{
        return !IS_PRODUCTION || [7, 1555,8176,2604,15579,324].includes(uid);
    };

    useEffect(()=>{
        if(isSystemMessageMasterUser()){
            getLastsRealtimeMessages();
        }

    },[]);

    const onClose = ()=>{
        setShowList(false);
        menuShow.close();
    };
    if(!isSystemMessageMasterUser())
        return <></>;
    return (
        <div>
            {realtimeMessageShow.isOpen && <SystemMessagePop {...realtimeMessageShow} />}
            <css.openMessagePopup onClick={()=>menuShow.open()} data-cy="changedDisplay" />
            {menuShow.isOpen && <List right={0} arrowIntoClick={()=>setShowList(oldShow=>!oldShow)} title={t('systemMessage')} {...menuShow} close={onClose} list={systemMessagesList} />}
        </div>
    );
}

export default SystemMessageMenu;