import {useSelector} from 'react-redux';
import NewTicketsEvent from './NewTicketsEvent';
import NewHubEvent from './newHubEvent/NewHubEvent';
import NewGivechakEvent from './newGivechakEvent/NewGivechakEvent';
import NewLecturesEvent from './newLecturesEvent/NewLecturesEvent';

function NewEvent() {
    const type=useSelector(s=>s.event.type);
    
    const generators={
        hub:NewHubEvent,
        givechak: NewGivechakEvent,
        timetable:NewLecturesEvent
    };

    const Comp=generators[type]||NewTicketsEvent;

    return (
        <Comp/>
    );
}

export default NewEvent;
