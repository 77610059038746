/* eslint-disable */
import {isMaster} from '../../functions/permission';
import * as css from './MasterPixel.css';
import React, {useState} from 'react';
import {TICK_DOMAIN_MY} from '../../config/config';
import readCookie from '../../library/readCookie';
import {useSelector} from 'react-redux';
import Table from "../newTable/Table";

interface LinkConfig {
    title: string;
    path: string;
    basePath?: string;
    type?: string;
    allowedUids?: number[];
}

const PATHS = {
    TICLI: '/office/ticli/',
    OFFICE: '/office/',
    AJAX: '/ajax/api/',
} as const;

interface IframeModalProps {
    isOpen: boolean;
    onClose: () => void;
    url: string;
}

const createLink = (domain: string, {path, basePath, type, title}: LinkConfig, tickchakToken?: string) => {
    if(path === 'title'){
        return {title, link: ''};
    }
    let link = `${domain}${basePath || PATHS.TICLI}${path}`;

    // Add type parameter if exists
    if(type) {
        link += `?type=${type}`;
    }

    // Add tickchak token if in local environment
    if(process.env.REACT_APP_ENV === 'local' && tickchakToken) {
        // Use ? if no query params exist, & if they do
        const separator = link.includes('?') ? '&' : '?';
        link += `${separator}tickchak_token=${tickchakToken}&tickchakNewApp`;
    }

    return {title, link};
};

interface LinkConfig {
    title: string;
    path: string;
    basePath?: string;
    type?: string;
    allowedUids?: number[];
}

function getStupidLinks(domain: string, tickchakToken?: string, currentUid?: number) {
    const allLinks: LinkConfig[] = [
        // Default links available to all masters
        {title: 'מסופים', path: 'title'},
        {title: 'הוספת מסוף חדש', path: 'mudul'},
        {title: 'ניהול מסופים באירועים', path: 'update_supplier'},
        {title: 'אירוע חריג! החלפת מסוף כללי בטיקצאק!', path: 'changeSupplierTickchak', allowedUids: [1555, 2069]},

        {title: 'פיקסלים', path: 'title'},
        {title: 'הוספת פיקסלים חכמה לאירועים', path: 'known_pixels'},
        {title: 'יצירת קישור והקפצה ללייב אירוע', path: 'new_events_til'},

        {title: 'גיבצ\'אק', path: 'title'},
        {title: 'החרגות חסימות אייפריים בגיבצ\'אק', path: 'new_model', type: 'givechak_dangerous_activity'},
        {title: 'חיבור בין התרמות גיבצ\'אק', path: 'givechak_connect_events'},

        {title: 'חיבורים', path: 'title'},
        {title: 'חיבור בין אירועים מרובים לאפליקציה', path: 'new_model_terminal'},
        {title: 'הוספת חיבור לאירוע לנדרים', path: 'new_model', type: 'nedarim'},
        {title: 'הוספת חיבור לאירוע לרישומון', path: 'new_model', type: 'rishumon'},
        {title: 'חיבור והרשאה לאירוע להתממשקות עם ימות המשיח', path: 'new_model', type: 'yemot_hamashiach'},

        {title: 'ווצאפ', path: 'title'},
        // {title: 'אירוע עם שליחה בווצאפ', path: 'new_model', type: 'whatsapp'},
        // {title: 'חבילה עם שליחה בווצאפ', path: 'new_model', type: 'whatsapp_package'},
        {title: 'אירוע עם שליחה בווצאפ JONI', path: 'new_model', type: 'whatsapp_joni'},
        {title: 'חבילה עם שליחה בווצאפ JONI', path: 'new_model', type: 'whatsapp_joni_package'},
        {title: 'אירועים עם הוספה אוטומטית לקבוצות וואצאפ', path: 'new_model', type: 'add_to_whatsapp_group'},
        {title: 'חשבונות עם הוספה אוטומטית לקבוצות וואצאפ', path: 'new_model', type: 'add_to_whatsapp_group_package'},

        {title: 'דשבורדים', path: 'title'},
        {title: 'צפה בדירוגי משתמשים', path: 'show_ratings'},
        {title: 'דשבורד', path: 'dashboard', basePath: PATHS.OFFICE, allowedUids: [1555, 2069]},
        {title: 'דשבורד ישן', path: 'MASTER?t=090TUENWQ3pmNZdHS1NUOsRzUxNTUW50d', allowedUids: [1555, 2069]},
        {title: 'מלכת הטיפשים דגם מתקדם', path: 'invoice_all', basePath: PATHS.OFFICE},

        {title: 'לייב', path: 'title'},
        {title: 'ניהול אמנים חדש!', path: 'manage_live', basePath: PATHS.AJAX, allowedUids: [1555, 2069]},
        {title: 'דפי אולמות קביעת עיר', path: 'page_edit_live', allowedUids: [7, 2069, 1555, 4712]},

        {title: 'חסימות', path: 'title'},
        {title: 'חסימות IP', path: 'blocked_ip_allowed', allowedUids: [7, 2069, 7423, 1555, 4712, 9860, 8176]},
        {title: 'חסימות יוזר', path: 'blocked_user', allowedUids: [2069, 1555, 7423]},

        {title: 'מוצר דחיפה', path: 'title'},
        {title: 'הגדרות מוצר דחיפה', path: 'update_specials'},

        {title: 'נוספים', path: 'title'},
        {title: 'באג בשליחת סמס! החלפת חברת שליחת סמס!', path: 'changeTickchakSmsSender', allowedUids: [1555, 2069]},
        {title: 'הורדת אקסל עסקאות', path: 'download_excel_transactions', allowedUids: [7, 2069]},
        {title: 'הקמה ועריכת וובהוק', path: 'webhook_management', allowedUids: [7, 324, 2069]},
        {title: 'תרגומים', path: 'translate', allowedUids: [2069]},
        {title: 'שינוי חבילה של אירוע', path: 'change_event_package', allowedUids: [7, 2069, 7423, 1555]},
        {title: 'איפוס מחיר הזמנה עסקת ייבוא בלבד', path: 'reset_order_price', allowedUids: [7, 2069, 7423, 1555]},
        // {title: 'אירועים עם אפשרות ביטול מתוך הכרטיס', path: 'new_model', type: 'cancel_from_ticket'},
        // {title: 'חשבונות עם אפשרות ביטול מתוך הכרטיס', path: 'new_model', type: 'cancel_from_ticket_package'},
    ];

    // Filter links based on current UID
    const filteredLinks = allLinks.filter(link =>
        !link.allowedUids || (currentUid && link.allowedUids.includes(currentUid))
    );

    return filteredLinks.map(config => createLink(domain, config, tickchakToken));
}

export const IframeModal: React.FC<IframeModalProps> = ({isOpen, onClose, url}) => {
    const [isLoading, setIsLoading] = useState(true);

    if(!isOpen) return null;

    return (
        <css.ModalOverlay onClick={onClose}>
            <css.ModalContent onClick={e => e.stopPropagation()}>
                <css.CloseButton onClick={onClose}>×</css.CloseButton>
                {isLoading && <css.Loader />}
                <css.StyledIframe
                    src={url}
                    title="Content"
                    onLoad={() => setIsLoading(false)}
                    style={{ opacity: isLoading ? 0 : 1 }}
                />
            </css.ModalContent>
        </css.ModalOverlay>
    );
};

function MasterPixel() {
    if(!isMaster()) {
        return null;
    }
    const [selectedUrl, setSelectedUrl] = useState<string>('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const title = 'מאסטר הפיקסלים';
    const search={
        onSearch: (val: React.SetStateAction<string>) => setSearchTerm(val),
        onSearchEnd:() => setSearchTerm(''),
        searchState:{
            setIsSearchState: () => {},
            isSearchState: true
        },

    };
    const tickchakToken = readCookie('tickchak_token');
    const uid = useSelector((s:any) => s.user?.uid);
    const stupidLinks = getStupidLinks(TICK_DOMAIN_MY, tickchakToken, uid);

    const handleLinkClick = (link: string) => {
        setSelectedUrl(link);
        setIsModalOpen(true);
    };

    const filteredLinks = stupidLinks.filter(link =>
        link.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const optionsList = filteredLinks.map(item => {
        return {
            text: item.title,
            link: item.link
        }
    })

    return (
        <>
            <Table
                list={optionsList?.map((item, index) => ({
                    comp: LinkRow,
                    props: {
                        ...item,
                        handleLinkClick
                    },
                    key: `link-${index}`,
                }))}
                searchProps={search}
                checked={undefined}
                buttonsChildren={undefined}
                title={title}
            />
            <IframeModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                url={selectedUrl}
            />
        </>
    );
}

function LinkRow(item: { index: number; handleLinkClick: any; text: string; link: string; }) {
    const {index, handleLinkClick, text, link} = item;
    const clickLink = link ? handleLinkClick : () => {};
    return (
        <css.Card key={index} onClick={() => clickLink(link)} className={link ? '' : 'title'}>
        {text}
            {link && <css.ExternalLinkIcon
                onClick={(e) => {
                    e.stopPropagation();
                    window.open(link, '_blank');
                }}
            />}

    </css.Card>
    )
}

export default MasterPixel;