import styled from 'styled-components';
import {BGImageCenter, desktopMedia} from '../../globalVariables/GlobalVariables';
import {pointsMenu} from '../../assets/symbols/symbols/Symbols.css';

export const button = styled.div`   
    width: 50px;
    height: 50px;
    ${BGImageCenter(pointsMenu, '20px')}
    ${desktopMedia}{
        display: none;
    }
`;

