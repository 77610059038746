import {useTranslation} from 'react-i18next';
import * as css from './TicketsTableHeaderGivechak.css';

function TicketsTableHeaderGivechak() {
    const {t} = useTranslation('realtime');

    return (
        <css.header>
            <css.ticketName>
                {t('ticketGivechakName')}
            </css.ticketName>
            <css.item>
                {t('price')}
            </css.item>
            <css.item>
                {t('soldTickchak')}
            </css.item>
            <css.item>  
                {t('soldPriceGivechak')}
            </css.item>
        </css.header>
    );
}
export default TicketsTableHeaderGivechak;
