import React from 'react';
import {WHITE} from '../../globalVariables/GlobalVariables';

function Accessibility({color = WHITE}: { color?: string }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="17.89" height="19.013" viewBox="0 0 17.89 19.013">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_3426" data-name="Rectangle 3426" width="17.89" height="19.013" fill="#fff" />
                </clipPath>
            </defs>
            <g id="Group_10018" data-name="Group 10018" transform="translate(1.852 1.97)">
                <g id="Group_10018-2" data-name="Group 10018" transform="translate(-1.852 -1.97)" clipPath="url(#clip-path)">
                    <path
                        id="Path_13740"
                        data-name="Path 13740"
                        d="M502.275,405.4a.674.674,0,0,0-.928-.216l-.668.415-2.475-4.074a.9.9,0,0,0-.768-.432h-5.67v-2.423h4.519a.674.674,0,1,0,0-1.347h-4.519v-1.815a.9.9,0,0,0-1.8,0v6.484a.9.9,0,0,0,.9.9h6.063l2.432,4c.038.062.081.108.121.166.009.018.026.036.036.053a.674.674,0,0,0,.928.216l1.609-1a.674.674,0,0,0,.216-.928"
                        transform="translate(-484.487 -390.191)"
                        fill={color}
                    />
                    <path
                        id="Path_13741"
                        data-name="Path 13741"
                        d="M413.983,3.535a1.768,1.768,0,1,0-1.768-1.768,1.768,1.768,0,0,0,1.768,1.768"
                        transform="translate(-407.602 0.002)"
                        fill={color}
                    />
                    <path
                        id="Path_13742"
                        data-name="Path 13742"
                        d="M6.054,637.887a4.256,4.256,0,0,1-1.193-8.342v-1.851a6.053,6.053,0,1,0,7.229,6.3h-1.8a4.261,4.261,0,0,1-4.239,3.894"
                        transform="translate(0 -620.671)"
                        fill={color}
                    />
                </g>
            </g>
        </svg>
    );
}

export default Accessibility;
