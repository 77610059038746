import AccountRolePermission from '../../components/rolePermission/AccountRolePermission';
import SideFormPopup from '../../../component/sideFormPopup/sideFormPopup';
import {useLocation, useHistory} from 'react-router-dom';
import SettingsBody from '../componnent/settingsBody/SettingsBody';
import PackageProducerBody from '../componnent/packageProducerBody/PackageProducerBody';
import PackageBank from '../componnent/packageBank/PackageBank';
import AccountTeam from '../componnent/accountTeam/AccountTeam';
import SubscriptionScreen from '../componnent/subscriptionScreen/SubscriptionScreen';
import {useSelector} from 'react-redux';
import {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {packageList, packageListClick} from '../../../layout/events/packageManagement/PackageManagement';

function Package(props) {
    const {packageShow} = props;
    const accountTitle = useSelector(state => state.account?.title);
    const formRef = useRef();
    const {t} = useTranslation('layout');
    const location = useLocation();
    let params = new URLSearchParams(location.search);
    const history = useHistory();
    const page = params.get('packagePage');
    const list = packageList.filter((item) => item.text !== page);
    list.forEach(item=>item.title=t(item.title));
    
    useEffect(() => () => {
        history.push({
            search:''
        });
    }, []);
    
    const body = {
        'package':<SettingsBody/>,
        'producer': <PackageProducerBody formRef={formRef}/>,
        'bank': <PackageBank formRef={formRef}/>,
        'team': <AccountTeam/>,
        'subscription': <SubscriptionScreen />
    };

    return (
        <AccountRolePermission rolePermission={'manage package'} views={[]}>
            <SideFormPopup saveText={'save'} menuClicked={(item, history, close) => packageListClick(item, history, close)}
                menuTitle={'packageSettings'} menuList={list} t={t} bodyComponents={body} 
                {...packageShow} formRef={formRef} popupName={accountTitle} page={page} />
        </AccountRolePermission>
        
    );

}
export default Package;
