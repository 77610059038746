import {useLocation} from 'react-router-dom';

export const useSearchParams = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const allParams = {};
    queryParams.forEach((value, key) => {
        allParams[key] = value.replace(/ /g, '+');//TODO: check
    });
    if((allParams as any).token) {
        (allParams as any).token = (allParams as any).token.replace(/ /g, '+');
    }
    return allParams;
};